import axios from "axios";
import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

const NormatividadDoc = () => {
  const [normativas, setNormativas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}normatividad/datos`
        );
        setNormativas(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const sortNormativas = (a, b) => {
    const orderA = a.orden !== undefined ? a.orden : Number.MAX_SAFE_INTEGER;
    const orderB = b.orden !== undefined ? b.orden : Number.MAX_SAFE_INTEGER;

    if (orderA === 0) return 1;
    if (orderB === 0) return -1;
    if (orderA < orderB) return -1;
    if (orderA > orderB) return 1;
    return 0;
  };

  const currentDate = new Date();
  const month = currentDate.toLocaleString("default", { month: "long" });
  const year = currentDate.getFullYear();

  return (
    <>
      <section className="container mt-4">
        {/* <h1 className="py-2 pt-3">Libro de Calificaciones</h1> */}

        {isLoading ? (
          <div className="text-center">
            <i className="fa-solid fa-spinner fa-spin icon fs-1 bg-stemis"></i>
            <p>Cargando normativas...</p>
          </div>
        ) : (
          <div className="row row-cols-1 row-cols-md-4 g-4">
            {normativas.sort(sortNormativas).map((normativa) => (
              <div key={normativa.id} className="col">
                <div className="card border-0  p-2 ">
                  <Link to={`/Normadetalles/${normativa.slug}`}>
                    <img
                      src={`${window.BACKEND_NORMATIVA}uploads/${normativa.foto_card}`}
                      className="card-img-top rounded-3 shadow-sm"
                      alt={normativa.titulo}
                      style={{
                        width: "100%",
                        height: "150px",
                        objectFit: "cover",
                      }}
                    />
                    <div className="mt-2">
                      <h5 className="card-title bg-stemis">
                        {normativa.titulo}
                      </h5>
                      <p className="card-text text-stemis-dark mt-n2">
                        Actualizado a {month} de {year}
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        )}
      </section>
    </>
  );
};
export default NormatividadDoc;
