import { Outlet } from "react-router-dom";
import SliderAlu from "../components/slideralum";

import NavBar from "../components/NavBar";
import { useState } from "react";

/* eslint-disable jsx-a11y/anchor-is-valid */

const Layoutalum = () => {
  const [role] = useState(sessionStorage.getItem("role"));

  const photo = sessionStorage.getItem("photo");

  return (
    <div className="container">
      <nav className="container navbar navbar-expand-xl ">
        <NavBar />
      </nav>

      <div
        className="container justify-content-center px-0  "
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <section
          className={`d-none d-sm-block `}
          // className={`d-none d-sm-block offcanvas offcanvas-start w-25`}
          // className={`d-none d-sm-block ${
          //   "d-sm-none" ? "" : "offcanvas offcanvas-start w-25"
          // }`}
          style={{ backgroundColor: "transparent", border: "none" }}
          tabindex="-10"
          id="offcanvas"
        >
          <div className="sidebar bg-body-secondary p-3 me-2 rounded-4 custom-sidebar">
            <div className="container-fluid d-flex flex-column align-items-center">
              <div className="mt-3">
                <img
                  src={`${window.BACKEND_URL}uploads/${photo}`}
                  alt=""
                  className="rounded-circle img-fluid"
                  style={{
                    border: "solid 3px #009688",
                    minWidth: "7rem",
                    minHeight: "7rem",
                    objectFit: "cover",
                    maxWidth: "7rem",
                    maxHeight: "7rem",
                  }}
                />
              </div>

              <h5 className="fs-5 pb-0 mb-0 pt-3">{role}</h5>
            </div>

            <hr />
            <SliderAlu />
          </div>
        </section>

        <div className="container mt-0 rounded-4 mb-3 bg-white">
          <Outlet />

          <br />
        </div>
      </div>
    </div>
  );
};

export default Layoutalum;
