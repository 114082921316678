import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import "datatables.net"; // Importa DataTables
import Swal from "sweetalert2";
import "datatables.net-bs5";
import { Link } from "react-router-dom";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es"; // Importa la localización en español

registerLocale("es", es); // Registra la localización en español

function Escritos() {
  const [loading, setLoading] = useState(true);
  const tableRef = useRef(null);
  const [sedejudi, setSedeJudicial] = useState([]);
  const [selectedJudicial, setSelectedJudicial] = useState(""); // Estado para el distrito judicial seleccionado
  const [juzgado, settipojuzgado] = useState([]);
  const [selectedJuzgado, setSelectedJuzgado] = useState("");

  const [disjudiciales, setJudicial] = useState([]);
  const [expediente, setExpediente] = useState([]);
  const [datamostrar, setdatamostrar] = useState([]);
  const [idexpediente, setidexpediente] = useState("");
  const [data_id] = useState(sessionStorage.getItem("_id"));

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${window.BACKEND_URL}alumno/expediente/filtro_busqueda/gets`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      const expedientesFiltrados = response.data.filter((exp) =>
        exp.datagrupo.some((grupo) =>
          grupo.alumnose.some((alumno) => alumno.alumno_id === data_id)
        )
      );

      let alumnoIdsSet = new Set();
      response.data.forEach((data) => {
        data.datagrupo.forEach((grupo) => {
          grupo.alumnose.forEach((alumno) => {
            alumnoIdsSet.add(alumno.alumno_id);
          });
        });
      });
      response.data.forEach((data) => {
        data.datacurso.forEach((grupo) => {
          alumnoIdsSet.add(grupo.docente_id);
        });
      });
      let alumnoIds = Array.from(alumnoIdsSet);

      const users = await axios.get(
        `${window.BACKEND_URL}alumno/users/get/ids`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
          params: {
            userIds: alumnoIds,
          },
        }
      );
      const allUsersAlumno = [];
      const allUsersDocente = [];
      const otrosRoles = [];
      users.data.forEach((user) => {
        if (user.role === "Docente") {
          allUsersDocente.push(user);
        } else if (user.role === "Alumno") {
          allUsersAlumno.push(user);
        } else {
          otrosRoles.push(user);
        }
      });

      let pretencioness = new Set();
      response.data.forEach((data) => {
        data.datagrupo.forEach((grupo) => {
          pretencioness.add(grupo.pretencion);
        });
      });

      let dataspretencionIds = Array.from(pretencioness);

      const pretenciones = await axios.get(
        `${window.BACKEND_URL}alumno/pretencion/getids`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
          params: {
            Ids: dataspretencionIds,
          },
        }
      );

      const allPretencion = pretenciones.data;

      const promisess = expedientesFiltrados.map(async (expediente) => {
        const updatedDataGrupo = await Promise.all(
          expediente.datagrupo.map(async (grupo) => {
            // Obtener los nombres de los alumnos

            const alumnosPromises = grupo.alumnose.map(async (alumno) => {
              const usuario = allUsersAlumno.find(
                (data) => data._id === alumno.alumno_id
              );

              if (usuario) {
                alumno.nombre = usuario.name;
              } else {
                console.error(
                  "Usuario no encontrado para el ID:",
                  alumno.alumno_id
                );
              }
            });

            await Promise.all(alumnosPromises);

            let pretensionData = {}; // Inicializa pretensionData fuera del bloque if

            const pretencion = allPretencion.find(
              (data) => data._id === grupo.pretencion
            );

            if (pretencion) {
              pretensionData = pretencion; // Asigna todos los datos de la pretensión si se encuentra
            } else {
              console.error(
                "Pretensión no encontrada para el grupo:",
                grupo.pretencion
              );
              // Otra acción si la pretensión no se encuentra
            }

            return {
              ...grupo,
              datapretension: pretensionData,
            };
          })
        );
        const updatedDataCursoPromises = expediente.datacurso.map(
          async (data) => {
            const docente = allUsersDocente.find(
              (user) => user._id === data.docente_id
            );

            if (docente) {
              data.namedocente = docente.name;
            } else {
              console.error(
                "Usuario no encontrado para el ID:",
                data.docente_id
              );
            }

            return data;
          }
        );

        const updatedDataCurso = await Promise.all(updatedDataCursoPromises);

        return {
          ...expediente,
          datagrupo: updatedDataGrupo,
          datacurso: updatedDataCurso,
        };
      });

      // Esperar a que todas las solicitudes se completen
      const updatedExpedientess = await Promise.all(promisess);

      setExpediente(updatedExpedientess);
    } catch (error) {
      console.error("Error fetching turnos:", error);
    } finally {
      setLoading(false);
    }
  };

  const getjuzgado = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}alumno/juzgados/get`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );
      settipojuzgado(response.data);
    } catch (error) {
      console.error("Error fetching turnos:", error);
    }
  };
  const fetchJudiciales = async () => {
    try {
      const response = await fetch(
        `${window.BACKEND_URL}alumno/distrito_judicial/get`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.json();

      if (Array.isArray(data)) {
        setJudicial(data);
      } else {
        console.error("La respuesta de los judiciales no es un array:");
      }
    } catch (error) {
      console.error("Error al obtener los judiciales:", error);
    }
  };

  const getsedejudi = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}alumno/sede_judicial/get`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setSedeJudicial(response.data);
    } catch (error) {
      console.error("Error fetching turnos:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchJudiciales();
    getsedejudi();

    getjuzgado();
  }, []);

  useEffect(() => {
    const initializeDataTable = async () => {
      if (!tableRef.current) {
        return;
      }

      // Limpiar DataTable si ya está inicializado
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }

      // Inicializar DataTable
      $(tableRef.current).DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
      });
    };

    if (!loading) {
      initializeDataTable();
    }

    // Limpieza al desmontar el componente
    return () => {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
    };
  }, [loading]);

  const handleJudicialChange = (e) => {
    setSelectedJudicial(e.target.value);
  };

  const [selectedYear, setSelectedYear] = useState(null);

  const handleChange = (year) => {
    setSelectedYear(year);
  };

  const [mostrarFormulariOne, setMostrarFormularioOne] = useState(false);

  const confirmsFormu = async (e) => {
    e.preventDefault();
    // Obtener el formulario utilizando su ID
    const form = document.getElementById("myForm");
    // Verificar si el formulario se encontró
    if (!form) {
      console.error("No se encontró el formulario con el ID proporcionado");
      return;
    }

    // Crear un objeto para almacenar los datos del formulario
    const dataform = {};

    // Recorrer todos los elementos del formulario
    for (let element of form.elements) {
      // Verificar si el elemento tiene un nombre y no es un botón
      if (element.name && element.type !== "submit") {
        // Agregar el valor del elemento al objeto formData
        dataform[element.name] = element.value;
      }
    }

    const filtrarExpedientes = (expedientes, filtro) => {
      return expedientes.filter((data) => {
        // Obtener el número de expediente del expediente actual
        const partes = data.nexpediente.split("-");
        const numeroExpedienteActual = partes[0];
        const anioexpediente = partes[1];

        // Verificar si el número de expediente del expediente actual contiene el filtro
        const cumpleFiltroNumeroExpediente =
          parseInt(numeroExpedienteActual) ===
          parseInt(filtro.filtronumeroExpediente);

        const filtrodistrito = data.demanda?.distrito_id.includes(
          filtro.filtrodistrito_id
        );

        const filtromateria = data.datagrupo[0]?.materia.includes(
          filtro.filtromateria
        );

        const filtrodsede = data.demanda?.sede_id.includes(
          filtro.filtrosede_id
        );

        const filtrojuzgado = data.demanda?.juzgado_id.includes(
          filtro.filtrojuzgado_id
        );
        const filtroanio = anioexpediente.includes(filtro.filtroanio);

        // Verificar más criterios de filtrado según sea necesario

        // Retornar true si el expediente cumple todos los criterios de filtrado
        return (
          cumpleFiltroNumeroExpediente &&
          filtrodistrito &&
          filtromateria &&
          filtrodsede &&
          filtrojuzgado &&
          filtroanio
        );
      });
    };

    // Definir el objeto filtro con los parámetros deseados
    const filtro = {
      filtronumeroExpediente: dataform.nexpediente,
      filtromateria: dataform.materia,
      filtrodistrito_id: dataform.distrito_id,
      filtrosede_id: dataform.sede_id,
      filtrojuzgado_id: dataform.juzgado_id,
      filtroanio: dataform.anio,
    };

    // Llamar a la función de filtrado pasando los expedientes y el objeto filtro
    const expedientesFiltrados = filtrarExpedientes(expediente, filtro);

    if (expedientesFiltrados[0]?.typedema) {
      if (expedientesFiltrados.length > 0) {
        Swal.fire({
          icon: "success",
          title: "Encontrado",
          text: "Se encontraron expedientes que coinciden.",
        });
        setMostrarFormularioOne(!mostrarFormulariOne);
        console.log(expedientesFiltrados);

        setidexpediente(expedientesFiltrados[0]._id);
        setdatamostrar(expedientesFiltrados);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "No se encontraron expedientes que coincidan.",
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "No se inicio la demanda.",
      });
    }
  };

  return (
    <>
      <div className="container-fluid rounded-end-4 position-relative m-0 p-0">
        <div className="container-fluid mt-3 py-3">
          <strong class=" px-3 pt-4">Buscar Escritos</strong>
        </div>
        {/* {loading ? (
          <p>Cargando...</p>
        ) : ( */}
        <section>
          <div className="container-fluid mt-3">
            <form className="row g-3 needs-validation" id="myForm">
              <div className="row">
                <div className="col-md-8 px-4">
                  <div className="row">
                    <div className="col-md-4 mt-2">
                      <label htmlFor="inputEmail3">
                        <p>
                          Materia<text className="text-danger">*</text>
                        </p>
                      </label>
                    </div>
                    <div className="col-md-8 p-0 mt-2">
                      <select
                        type="text"
                        className="form-select"
                        id="materia"
                        name="materia"
                      >
                        <option value="">Seleccione una materia</option>
                        <option value="CIVIL">CIVIL</option>
                        <option value="CONSTITUCIONAL">CONSTITUCIONAL</option>
                        <option value="CONTENCION ADMINISTRATIVA">
                          CONTENCION ADMINISTRATIVA
                        </option>
                        <option value="FAMILIA CIVIL">FAMILIA CIVIL</option>
                        <option value="LABORAL">LABRORAL</option>{" "}
                      </select>
                      <div className="invalid-feedback">Campo Obligatorio.</div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4 mt-2">
                      <label htmlFor="inputEmail3">
                        <p>
                          Distrito Judicial
                          <text className="text-danger">*</text>
                        </p>
                      </label>
                    </div>
                    <div className="col-md-8 p-0 mt-2">
                      <select
                        type="text"
                        className="form-select"
                        name="distrito_id"
                        id="distrito_id"
                        onChange={handleJudicialChange}
                      >
                        <option value="">
                          Selecciona un distrito judicial:
                        </option>
                        {disjudiciales
                          .sort((a, b) => a.nombre.localeCompare(b.nombre))
                          .map((judicial) => (
                            <option key={judicial._id} value={judicial._id}>
                              {judicial.nombre}
                            </option>
                          ))}
                      </select>
                      <div className="invalid-feedback">Campo Obligatorio.</div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4 mt-2">
                      <label htmlFor="inputEmail3">
                        <p>
                          Sede<text className="text-danger">*</text>
                        </p>
                      </label>
                    </div>
                    <div className="col-md-8 p-0 mt-2">
                      <select
                        className="form-select"
                        id="sede_id"
                        name="sede_id"
                        onChange={(e) => {
                          const select = sedejudi.find(
                            (data) => data._id === e.target.value
                          );
                          setSelectedJuzgado(select);
                        }}
                        required
                      >
                        <option value="">Selecciona una Sede:</option>
                        {sedejudi
                          .filter((sede) => sede.distrito === selectedJudicial)
                          .sort((a, b) => a.sede.localeCompare(b.sede))
                          .map((sedej) => (
                            <option key={sedej._id} value={sedej._id}>
                              {sedej.sede}
                            </option>
                          ))}
                      </select>
                      <div className="invalid-feedback">Campo Obligatorio.</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 mt-2">
                      <label htmlFor="inputEmail3">
                        <p>
                          Juzgado<text className="text-danger">*</text>
                        </p>
                      </label>
                    </div>
                    <div className="col-md-8 p-0 mt-2">
                      <select
                        type="text"
                        className="form-select"
                        name="juzgado_id"
                        id="juzgado_id"
                      >
                        <option value="">Selecciona un juzgado:</option>

                        {juzgado
                          .filter(
                            (datt) => datt.sede_id === selectedJuzgado._id
                          )
                          .sort((a, b) =>
                            a.nombrejuzgado.localeCompare(b.nombrejuzgado)
                          )
                          .map((datt) => (
                            <option key={datt._id} value={datt._id}>
                              {datt.nombrejuzgado}
                            </option>
                          ))}
                      </select>
                      <div className="invalid-feedback">Campo Obligatorio.</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 mt-2">
                      <label htmlFor="inputEmail3">
                        <p>
                          Año<text className="text-danger">*</text>
                        </p>
                      </label>
                    </div>
                    <div className="col-md-8 p-0 mt-2">
                      <DatePicker
                        selected={selectedYear}
                        onChange={handleChange}
                        dateFormat="yyyy"
                        showYearPicker
                        className="form-control"
                        placeholderText="Seleccionar año"
                        name="anio"
                        id="anio"
                        required
                        locale="es"
                        timeCaption="Hora"
                      />
                      <div className="invalid-feedback">Campo Obligatorio.</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 mt-2">
                      <label htmlFor="inputEmail3">
                        <p>
                          N° de Expediente
                          <text className="text-danger">*</text>
                        </p>
                      </label>
                    </div>
                    <div className="col-md-8 p-0 mt-2">
                      <input
                        type="text"
                        className="form-control"
                        name="nexpediente"
                        id="nexpediente"
                      />
                      <div className="invalid-feedback">Campo Obligatorio.</div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6 py-4">
                      <a
                        id="myButton"
                        className="btn btn-primary btn px-5"
                        type="submit"
                        onClick={confirmsFormu}
                      >
                        Buscar
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          {mostrarFormulariOne && (
            <div>
              <hr />
              <div className="card-body">
                {loading ? (
                  <p>Cargando.......</p>
                ) : (
                  <>
                    <table
                      className="table table-bordered table-striped table-hover table-responsive"
                      ref={tableRef}
                    >
                      <thead>
                        <tr>
                          <th>N° Expediente </th>
                          <th>Materia</th>
                          <th>Pretencion</th>
                          <th>Docente</th>

                          <th>Especialista </th>
                          <th>Demandante</th>
                          <th>Demandado</th>
                          <th>Juez</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {datamostrar.map((data) => (
                          <tr key={data._id}>
                            <td>{data.nexpediente}</td>
                            <td>{data.datagrupo[0]?.datapretension?.tipo}</td>
                            <td>{data.datagrupo[0]?.datapretension?.nombre}</td>
                            <td>{data.datacurso[0]?.namedocente}</td>

                            <td>
                              {data.datagrupo[0]?.alumnose
                                .filter(
                                  (alumno) => alumno.rol_id === "Especialista"
                                )
                                .map((alumno) => alumno.nombre)
                                .join(", ")}
                            </td>
                            <td>
                              {data.datagrupo[0]?.alumnose
                                .filter(
                                  (alumno) => alumno.rol_id === "Demandante"
                                )
                                .map((alumno) => alumno.nombre)
                                .join(", ")}
                            </td>
                            <td>
                              {data.datagrupo[0]?.alumnose
                                .filter(
                                  (alumno) => alumno.rol_id === "Demandado"
                                )
                                .map((alumno) => alumno.nombre)
                                .join(", ")}
                            </td>
                            <td>
                              {data.datagrupo[0]?.alumnose
                                .filter((alumno) => alumno.rol_id === "Juez")
                                .map((alumno) => alumno.nombre)
                                .join(", ")}
                            </td>
                            <td>
                              <Link
                                to={`/${data._id}/add_escritos`}
                                id="myButton"
                                className="btn btn-primary"
                              >
                                Agregar escritos
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {datamostrar[0].cuaderno &&
                      datamostrar[0].cuaderno.length > 0 && (
                        <>
                          <div className="container-fluid ">
                            <strong class=" ">Cuadernos</strong>
                          </div>
                          <table
                            className="table w-100  table-bordered table-striped table-hover table-responsive"
                            ref={tableRef}
                          >
                            <thead>
                              <tr>
                                <th>N° Expediente </th>
                                <th>Tipo de cuaderno</th>
                                <th>Fecha</th>

                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {datamostrar[0].cuaderno.map((cuader, index) => (
                                <tr key={index}>
                                  <td>{cuader.nexpediente}</td>
                                  <td>{cuader.nombre}</td>
                                  <td>
                                    {`${cuader.fech.slice(
                                      0,
                                      10
                                    )} - ${cuader.fech.slice(11, 19)}`}
                                  </td>

                                  <td>
                                    <Link
                                      to={`/${idexpediente}/${cuader.cuaderno_id}/add_escritos`}
                                      id="myButton"
                                      className="btn btn-primary"
                                    >
                                      Agregar escritos
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </>
                      )}
                  </>
                )}
              </div>
            </div>
          )}
        </section>
        {/* )} */}
      </div>
    </>
  );
}

export default Escritos;
