import React, { useRef, useState } from "react";
import { Page, Text, View, Document, Image, pdf } from "@react-pdf/renderer";
import { PDFDocument } from "pdf-lib";

import axios from "axios";

import JsBarcode from "jsbarcode";

const PDF = ({
  data_href,
  data_expe,
  data_demanda,
  fecha,
  destinatario,
  numnotifica,
}) => {
  const [numhojas, SetNumHojas] = useState("");

  const barcodeCanvasRef = useRef(null);

  const generateBarcode = (data) => {
    if (!barcodeCanvasRef.current) {
      const canvas = document.createElement("canvas");
      barcodeCanvasRef.current = canvas;
    }
    const canvas = barcodeCanvasRef.current;

    JsBarcode(canvas, data, { format: "CODE128" });

    return new Promise((resolve) => {
      setTimeout(() => {
        const dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      }, 100);
    });
  };

  const pageStyles = {
    flexDirection: "row",
    backgroundColor: "#fff",
    padding: 30,
  };
  const sectionStyles = {
    flexGrow: 1,
    marginHorizontal: 5,
  };
  const sectionStyles2 = {
    flexGrow: 1,
  };
  const titleStyles = {
    fontSize: 12,
    marginBottom: 5,
    textAlign: "center", // Centrado
    borderBottom: "1px solid black", // Línea de subrayado
  };
  const titleStyles2 = {
    fontSize: 15,
    marginBottom: 5,

    textAlign: "center", // Centrado
  };
  const textitemlStyles = {
    fontWeight: "bold",
    fontSize: "8px",
    width: 170,
  };
  const textitemlStyles2 = {
    fontWeight: "bold",
    fontSize: "8px",
    width: 100,
    marginBottom: 2,
  };
  const textitemlStyles3 = {
    fontWeight: "bold",
    fontSize: "8px",
  };
  const itemStyles = {
    flexDirection: "row",
    justifyContent: "space-between", // Alinear los elementos a lo largo del eje principal con espacio entre ellos

    // borderBottom: "10px"
  };
  const itemStyles2 = {
    flexDirection: "row",

    alignItems: "flex-start",
    borderBottom: "1px solid black",
  };
  const itemStyles3 = {
    flexDirection: "row",

    alignItems: "flex-start",

    marginTop: 4,
  };
  const itemElementStyles = {
    fontWeight: "bold",
    fontSize: "8px",
    width: 95,
  };
  const itemElementStyles2 = {
    fontWeight: "bold",
    fontSize: "8px",
  };

  const Demandantes = data_expe.alumnose
    .filter((item) => item.rol_id === "Demandante")
    .map((item) => item.nombre)
    .join(", ");

  const Demandados = data_expe.alumnose
    .filter((item) => item.rol_id === "Demandado")
    .map((item) => item.nombre)
    .join(", ");

  const Juezes = data_expe.alumnose
    .filter((item) => item.rol_id === "Juez")
    .map((item) => item.nombre)
    .join(", ");

  const Especialistas = data_expe.alumnose
    .filter((item) => item.rol_id === "Especialista")
    .map((item) => item.nombre)
    .join(", ");

  const pdfDocFromJSX = (
    <Document>
      <Page size="A4" style={pageStyles}>
        <View style={sectionStyles}>
          {/* Inicio  */}
          {/* Data inicial  */}
          <View style={itemStyles}>
            <Text style={{ ...textitemlStyles2, width: 300 }}></Text>

            <Image
              src={`${window.BACKEND_URL}uploads/logo.png`}
              // src="https://upload.wikimedia.org/wikipedia/commons/5/58/Poder_Judicial_del_Peru.png"
              style={{
                paddingLeft: 15,
                maxWidth: "70px",
                alignItems: "end",
                maxHeight: "70px",
              }}
            />
          </View>
          <View style={itemStyles}>
            <Text style={{ ...textitemlStyles2, width: 300 }}>
              PODER JUDICIAL DEL PERU
            </Text>

            <Text style={textitemlStyles3}>{data_expe.data_expe}</Text>
          </View>
          <View style={itemStyles}>
            <Text style={{ ...textitemlStyles2, width: 300 }}>
              CORTE SUPERIOR DE JUSTICIA
            </Text>

            <Text style={textitemlStyles3}>.</Text>
          </View>
          <View style={itemStyles}>
            <Text style={{ ...textitemlStyles2, width: 300 }}>
              {data_demanda?.distrito}
            </Text>

            <Text style={textitemlStyles3}>.</Text>
          </View>
          <View style={itemStyles}>
            <Text
              style={{
                ...textitemlStyles2,
                width: 300,
                marginBottom: 10,
              }}
            >
              {data_demanda?.sede}
            </Text>

            <Text style={textitemlStyles3}>.</Text>
          </View>
          {/* Titulos  */}
          <Text style={titleStyles2}>CEDULA ELECTRONICA</Text>
          <Text style={titleStyles2}>EXPEDIENTE JUDICIAL ELECTRÓNICO</Text>

          <Image
            src={generateBarcode("stemis.com.pe")}
            style={{
              minHeight: "15%",
              minWidth: "90%",
              maxHeight: "15%",
              maxWidth: "90%",
              paddingLeft: 40,
            }}
          />

          {/* Notificacion */}
          <Text style={titleStyles}>NOTIFICACION N° {numnotifica}</Text>
          <View style={sectionStyles2}>
            <View style={itemStyles}>
              <Text style={textitemlStyles2}>EXPEDIENTE</Text>
              <Text style={textitemlStyles}>{data_expe.nexpediente}</Text>
              <Text style={textitemlStyles2}>JUZGADO</Text>
              <Text style={textitemlStyles}>{data_demanda?.juzgado}</Text>
            </View>

            <View style={itemStyles}>
              <Text style={textitemlStyles2}>JUEZ</Text>
              <Text style={textitemlStyles}>{Juezes}</Text>
              <Text style={textitemlStyles2}>ESPECIALISTA LEGAL</Text>
              <Text style={textitemlStyles}>{Especialistas}</Text>
            </View>

            <View style={itemStyles2}>
              <Text style={itemElementStyles}>MATERIA</Text>
              <Text style={itemElementStyles2}> {data_expe.materia}</Text>
            </View>

            <View style={itemStyles3}>
              <Text style={itemElementStyles}>DEMANDANTE</Text>
              <Text style={itemElementStyles2}>: {Demandantes}</Text>
            </View>
            <View style={itemStyles2}>
              <Text style={itemElementStyles}>DEMANDADO</Text>
              <Text style={itemElementStyles2}>: {Demandados}</Text>
            </View>
            <View style={itemStyles3}>
              <Text style={itemElementStyles}>DESTINATARIO</Text>
              <Text style={itemElementStyles2}>
                : {destinatario ? Demandantes : Demandados}
              </Text>
            </View>
            <View style={{ ...itemStyles3, marginTop: 25 }}>
              <Text style={itemElementStyles}>DIRECCION</Text>
              <Text style={itemElementStyles2}> : info@stemis.com.pe </Text>
            </View>

            <Text style={{ ...itemElementStyles, width: 300, marginTop: 25 }}>
              Se adjunta Resolución de fecha {fecha} {" a Fjs :" + numhojas}
            </Text>
          </View>
          {/* <View style={totalStyles}>
            <Text style={labelStyles}>9 DE AGOSTO DE 2022:</Text>
          </View> */}
        </View>
      </Page>
    </Document>
  );

  const fetchData = async () => {
    try {
      // Descargar el PDF desde el backend

      const response = await axios.get(
        `${window.BACKEND_URL}uploads/${data_href}`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
          responseType: "blob", // Obtener el archivo como Blob
        }
      );

      const pdfBackendBlob = response.data;

      // Crear un nuevo documento PDF donde se combinarán los PDFs
      const combinedPdfDoc = await PDFDocument.create();

      // Cargar el PDF del backend como un documento
      const pdfBackendDoc = await PDFDocument.load(
        await pdfBackendBlob.arrayBuffer()
      );
      const numPagesBackend = pdfBackendDoc.getPageCount();

      SetNumHojas(numPagesBackend);

      // Convertir el documento JSX a un Blob
      const pdfBlob = await pdf(pdfDocFromJSX).toBlob();
      const pdfFromJSXDoc = await PDFDocument.load(await pdfBlob.arrayBuffer());

      // Combinar los dos PDFs
      const copiedJSXPages = await combinedPdfDoc.copyPages(
        pdfFromJSXDoc,
        pdfFromJSXDoc.getPageIndices()
      );
      copiedJSXPages.forEach((page) => combinedPdfDoc.addPage(page));

      // Agregar las páginas del PDF del backend al documento combinado
      // const copiedBackendPages = await combinedPdfDoc.copyPages(
      //   pdfBackendDoc,
      //   pdfBackendDoc.getPageIndices()
      // );
      // copiedBackendPages.forEach((page) => combinedPdfDoc.addPage(page));

      // Guardar el documento combinado como un Blob
      const combinedPdfBytes = await combinedPdfDoc.save();

      // const numPagesCombined = combinedPdfDoc.getPageCount();
      // console.log(
      //   `El documento PDF combinado tiene ${numPagesCombined} páginas.`
      // );

      const combinedPdfBlob = new Blob([combinedPdfBytes], {
        type: "application/pdf",
      });

      // Crear una URL para el Blob
      const url = URL.createObjectURL(combinedPdfBlob);

      const link = document.createElement("a");
      link.href = url;
      link.download = "Notificacion.pdf";
      link.click();
    } catch (error) {
      console.error("Error fetching :", error);
    }
  };

  return (
    <>
      <button
        className="btn   bg-stemis"
        style={{ fontSize: "35px" }}
        onClick={fetchData}
      >
        <i className="fa-solid fa-file-arrow-down "></i>
      </button>
    </>
  );
};

export default PDF;
