import React, { useState, useEffect } from "react";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import "datatables.net"; // Importa DataTables
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
function Demanda() {
  const [username] = useState(sessionStorage.getItem("userName"));
  const [_id] = useState(sessionStorage.getItem("_id"));

  // const [selectedJudicial, setSelectedJudicial] = useState(""); // Estado para el distrito judicial seleccionado
  const [sala, setsala] = useState([]);
  const [salasupre, setsalasupre] = useState([]);

  // Data importante
  const [distrito_judicial, setJudicial] = useState([]);
  const [sede_judi, setSedeJudicial] = useState([]);
  const [juzgado, settipojuzgado] = useState([]);
  const [numexpe, setnumexpe] = useState([]);

  const [tipo_juz, settipojuzg] = useState([]);
  const [selectedJudicial, setSelectedJudicial] = useState("");
  const [selectedSede, setSelectedJuzgado] = useState("");
  const [seleJuzgado, setSeleJuzgado] = useState("");
  const [seletipoJuzgado, setSeletipoJuzgado] = useState("");

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const fetchnumexpe = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${window.BACKEND_URL}alumno/expediente/num_expediente/get/${id}`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      const alumno = response.data[0]?.grupo_id[0]?.alumnose.find(
        (alumno) => alumno.alumno_id === _id
      );

      if (!alumno) {
        console.error("No se encontró el alumno con _id:", _id);
        return;
      }

      // Dividir el número de expediente en partes
      const partes = response.data[0].nexpediente.split("-");

      setnumexpe(partes);
    } catch (error) {
      console.error("Error fetching :", error);
    } finally {
      setLoading(false);
    }
  };

  const getsalasupre = async () => {
    try {
      const response = await fetch(
        `${window.BACKEND_URL}alumno/sala_suprema/get`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.json();

      if (Array.isArray(data)) {
        setsalasupre(data);
      } else {
        console.error("La respuesta de los judiciales no es un array:");
      }
    } catch (error) {
      console.error("Error al obtener los judiciales:", error);
    }
  };
  const getsala = async () => {
    try {
      const response = await fetch(`${window.BACKEND_URL}alumno/salas/get`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      });

      const data = await response.json();

      if (Array.isArray(data)) {
        setsala(data);
      } else {
        console.error("La respuesta de los judiciales no es un array:");
      }
    } catch (error) {
      console.error("Error al obtener los judiciales:", error);
    }
  };
  const getsede_judicial = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}alumno/sede_judicial/demanda/get`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      setSedeJudicial(response.data);
    } catch (error) {
      console.error("Error fetching turnos:", error);
    }
  };
  const getdistrito_judicial = async () => {
    try {
      const response = await fetch(
        `${window.BACKEND_URL}alumno/distrito_judicial/get`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      const data = await response.json();

      if (Array.isArray(data)) {
        setJudicial(data);
      } else {
        console.error("La respuesta de los judiciales no es un array:");
      }
    } catch (error) {
      console.error("Error al obtener los judiciales:", error);
    }
  };
  const getjuzgado = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}alumno/juzgados/get`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      settipojuzgado(response.data);
    } catch (error) {
      console.error("Error fetching turnos:", error);
    }
  };
  const gettipo_juzgo = async () => {
    try {
      const response = await fetch(
        `${window.BACKEND_URL}alumno/tipo_juzgado/get`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      const data = await response.json();

      if (Array.isArray(data)) {
        settipojuzg(data);
      } else {
        console.error("La respuesta de los judiciales no es un array:");
      }
    } catch (error) {
      console.error("Error al obtener los judiciales:", error);
    }
  };

  useEffect(() => {
    getdistrito_judicial();
    getsede_judicial();
    gettipo_juzgo();
    getjuzgado();
    getsala();
    getsalasupre();
    fetchnumexpe();
  }, []);

  const handleCancel = () => {
    window.history.back();
  };
  const { id } = useParams();
  const [costosDisabled, setCostosDisabled] = useState(false);

  const confirmsFormu = async (e) => {
    e.preventDefault();

    if (submitting) {
      return;
    }

    setSubmitting(true); // Marcar el formulario como enviado

    Swal.fire({
      title: "Cargando",
      text: "Subiendo archivos...",
      timer: 2000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    // Obtener el formulario utilizando su ID
    const form = document.getElementById("myForm");
    // Verificar si el formulario se encontró
    if (!form) {
      console.error("No se encontró el formulario con el ID proporcionado");
      return;
    }

    const demanda = new FormData();
    demanda.append("monto_soles", e.target.montosoles.value);
    demanda.append("observaciones", e.target.observaciones.value);
    demanda.append("sumilla", e.target.sumilla.value);
    demanda.append("file", e.target.file.files[0]);
    demanda.append(
      "sala_id",
      e.target.sala_id.value ? e.target.sala_id.value : ""
    );
    demanda.append(
      "sala_suprema_id",
      e.target.sala_suprema_id.value ? e.target.sala_suprema_id.value : ""
    );

    demanda.append("juzgado_id", e.target.juzgado_id.value);
    demanda.append("sede_id", e.target.sede_id.value);
    demanda.append("distrito_id", e.target.distrito_id.value);
    demanda.append("user", username);
    demanda.append("alumno_id", _id);

    const nuevoNexpediente =
      numexpe[0] +
      "-" +
      numexpe[1] +
      "-" +
      numexpe[2] +
      "-" +
      selectedSede.numero +
      "-" +
      seletipoJuzgado.abrevacion +
      "-" +
      numexpe[3] +
      "-" +
      seleJuzgado.njuzgado;

    const dataexpe = {
      nexpediente: nuevoNexpediente,
      typedema: true,
    };

    try {
      await axios.put(
        `${window.BACKEND_URL}alumno/expediente/demanda/put/${id}`,
        demanda,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      await axios.put(
        `${window.BACKEND_URL}alumno/expediente/getid/${id}`,
        dataexpe,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      // Mostrar un mensaje de éxito al usuario
      Swal.fire({
        icon: "success",
        title: "Guardado",
        text: "¡Se guardó correctamente!",
      });
      window.history.back();
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false); // Marcar el formulario como no enviado
    }
  };

  // };

  return (
    <>
      <div className="container-fluid rounded-end-4 position-relative m-0 p-0">
        <div className="container-fluid mt-3 py-1">
          <strong class=" px-3 pt-4">Ingreso de Demandas</strong>
        </div>
        {loading ? (
          <p>Cargando...</p>
        ) : (
          <section>
            <div className="container-fluid mt-3 py-2">
              <h3 class=" px-3 pt-4">Datos del organo jurisdiccional</h3>
            </div>
            <form
              className="needs-validation"
              id="myForm"
              onSubmit={confirmsFormu}
            >
              <div className="container-fluid mt-3">
                <div className="row g-3 needs-validation">
                  <div className="row">
                    <div className="col-md-8 px-4">
                      <div className="row">
                        <div className="col-md-4 mt-2">
                          <label htmlFor="inputEmail3">
                            <p>
                              Distrito Judicial
                              <text className="text-danger">*</text>
                            </p>
                          </label>
                        </div>
                        <div className="col-md-8 p-0 mt-2">
                          <select
                            type="text"
                            className="form-select"
                            name="distrito_id"
                            id="distrito_id"
                            onChange={(e) => {
                              setSelectedJudicial(e.target.value);
                            }}
                          >
                            <option value="">
                              Selecciona un distrito judicial:
                            </option>
                            {distrito_judicial
                              .sort((a, b) => a.nombre.localeCompare(b.nombre))
                              .map((judicial) => (
                                <option key={judicial._id} value={judicial._id}>
                                  {judicial.nombre}
                                </option>
                              ))}
                          </select>
                          <div className="invalid-feedback">
                            Campo Obligatorio.
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 mt-2">
                          <label htmlFor="inputEmail3">
                            <p>
                              Sede<text className="text-danger">*</text>
                            </p>
                          </label>
                        </div>
                        <div className="col-md-8 p-0 mt-2">
                          <select
                            type="text"
                            className="form-select"
                            id="sede_id"
                            name="sede_id"
                            onChange={(e) => {
                              const select = sede_judi.find(
                                (data) => data._id === e.target.value
                              );
                              setSelectedJuzgado(select);
                            }}
                          >
                            <option value="">Selecciona una Sede:</option>
                            {sede_judi
                              .filter(
                                (sede) => sede.distrito === selectedJudicial
                              )
                              .sort((a, b) => a.sede.localeCompare(b.sede))
                              .map((sedej) => (
                                <option key={sedej._id} value={sedej._id}>
                                  {sedej.sede}
                                </option>
                              ))}
                          </select>
                          <div className="invalid-feedback">
                            Campo Obligatorio.
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 mt-2">
                          <label htmlFor="inputEmail3">
                            <p>
                              Juzgado<text className="text-danger">*</text>
                            </p>
                          </label>
                        </div>
                        <div className="col-md-8 p-0 mt-2">
                          <select
                            type="text"
                            className="form-select"
                            name="juzgado_id"
                            id="juzgado_id"
                            onChange={(e) => {
                              const selectedJuzgadoData = juzgado.find(
                                (data) => data._id === e.target.value
                              );

                              const titojuzgado = tipo_juz.find(
                                (data) =>
                                  data._id === selectedJuzgadoData.tijuzgado_id
                              );

                              setSeletipoJuzgado(titojuzgado);

                              setSeleJuzgado(selectedJuzgadoData);
                            }}
                          >
                            <option value="">Selecciona un juzgado:</option>

                            {juzgado
                              .filter(
                                (datt) => datt.sede_id === selectedSede._id
                              )
                              .sort((a, b) =>
                                a.nombrejuzgado.localeCompare(b.nombrejuzgado)
                              )
                              .map((datt) => (
                                <option key={datt._id} value={datt._id}>
                                  {datt.nombrejuzgado}
                                </option>
                              ))}
                          </select>
                          <div className="invalid-feedback">
                            Campo Obligatorio.
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 mt-2">
                          <label htmlFor="inputEmail3">
                            <p>
                              Sala<text className="text-danger">*</text>
                            </p>
                          </label>
                        </div>
                        <div className="col-md-8 p-0 mt-2">
                          <select
                            type="text"
                            className="form-select"
                            name="sala_id"
                            id="sala_id"
                          >
                            <option value="">Seleccionar sala:</option>

                            {sala
                              .filter(
                                (datt) => datt.sede_id === selectedSede._id
                              )
                              .sort((a, b) => a.nombre.localeCompare(b.nombre))
                              .map((datt) => (
                                <option key={datt._id} value={datt._id}>
                                  {datt.nombre}
                                </option>
                              ))}
                          </select>
                          <div className="invalid-feedback">
                            Campo Obligatorio.
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 mt-2">
                          <label htmlFor="inputEmail3">
                            <p>
                              Sala Suprema<text className="text-danger">*</text>
                            </p>
                          </label>
                        </div>
                        <div className="col-md-8 p-0 mt-2">
                          <select
                            type="text"
                            className="form-select"
                            name="sala_suprema_id"
                            id="sala_suprema_id"
                          >
                            <option value="">Seleccionar sala Suprema:</option>

                            {salasupre
                              .filter(
                                (datt) => datt.materia === seleJuzgado.materia
                              )
                              .sort((a, b) => a.nombre.localeCompare(b.nombre))
                              .map((datt) => (
                                <option key={datt._id} value={datt._id}>
                                  {datt.nombre}
                                </option>
                              ))}
                          </select>
                          <div className="invalid-feedback">
                            Campo Obligatorio.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div>
                <div className="container-fluid mt-3 py-2">
                  <h3 class=" px-3 pt-4">Datos del expediente</h3>
                </div>
                <div className="container-fluid mt-3">
                  <div className="row g-3 needs-validation">
                    <div className="row">
                      <div className="col-md-8 px-4">
                        <div className="row">
                          <div className="col-md-4 mt-2">
                            <label htmlFor="inputEmail3">
                              <p>
                                Sumilla<text className="text-danger">*</text>
                              </p>
                            </label>
                          </div>
                          <div className="col-md-8 p-0 mt-2">
                            <input
                              type="text"
                              name="sumilla"
                              id="sumilla"
                              className="form-control"
                            />
                            <div className="invalid-feedback">
                              Campo Obligatorio.
                            </div>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-4">
                            <label htmlFor="inputEmail3">
                              <p>Observaciones</p>
                            </label>
                          </div>
                          <div className="col-md-8 p-0 mt-2">
                            <textarea
                              className="form-control"
                              name="observaciones"
                              id="observaciones"
                              style={{
                                border: "1px solid rgb(172, 172, 172)",
                                height: "100px",
                                fontSize: "14px",
                                color: "#4b4b4b",
                              }}
                            ></textarea>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4 mt-2">
                            <label htmlFor="inputEmail3">
                              <p>
                                Indeterminado
                                <text className="text-danger">*</text>
                              </p>
                            </label>
                          </div>
                          <div className="col-md-8 p-0 mt-2">
                            <a
                              className={`btn ${
                                costosDisabled ? "btn-primary" : "btn-secondary"
                              } px-2 `}
                              onClick={() => setCostosDisabled(!costosDisabled)}
                            ></a>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4 mt-2">
                            <label htmlFor="inputEmail3">
                              <p>
                                Monto en soles
                                <text className="text-danger">*</text>
                              </p>
                            </label>
                          </div>
                          <div className="col-md-8 p-0 mt-2">
                            <input
                              type="number"
                              name="montosoles"
                              className="form-control"
                              id="montosoles"
                              disabled={costosDisabled}
                            />
                            <div className="invalid-feedback">
                              Campo Obligatorio.
                            </div>
                          </div>
                        </div>

                        <div className="row mt-3 ">
                          <div className="col-md-12 px-2">
                            <div className="input-group">
                              <input
                                accept="application/pdf"
                                type="file"
                                name="file"
                                className="form-control "
                                id="file"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-6 p-0 mb-3 mt-4">
                            <a
                              type="button"
                              className="btn px-5"
                              style={{
                                backgroundColor: "#ffffff",
                                color: "#868686",
                                borderColor: "#868686",
                                textAlign: "right",
                                fontWeight: "500",
                              }}
                              onClick={handleCancel}
                            >
                              Cancelar
                            </a>
                          </div>
                          <div className="col-6 mb-4 mt-4 d-flex flex-row-reverse ">
                            <button
                              id="myButton"
                              className="btn btn-primary btn px-5"
                              type="submit"
                              disabled={submitting} // Deshabilitar el botón cuando el formulario se está enviando
                            >
                              {submitting ? "Enviando..." : "Grabar"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
        )}
      </div>
    </>
  );
}

export default Demanda;
