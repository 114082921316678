import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

import Select from "react-select";

const AddGrupoC = () => {
  const { id } = useParams();
  const [usuarios, setUsuarios] = useState([]);
  const [pretenciones, setPretenciones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedMateria, setSelectedMateria] = useState("");
  const [selectedAlumnos, setSelectedAlumnos] = useState({
    alumnos: {
      Demandante: [],
      Demandado: [],
      Juez: [],
      Especialista: [],
    },
  });

  const [selectedPretencion, setSelectedPretencion] = useState("");

  const handleCancel = () => {
    window.history.back();
  };

  const getCursos = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}docente/cursos/get/${id}`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (!response.data) {
        console.error("Error: No se encontraron datos de cursos.");
        return;
      }

      if (response.data.alumnos_id) {
        const alumnosIdArray = response.data.alumnos_id;
        const nombresPromises = alumnosIdArray.map(async (alumno) => {
          const userResponse = await axios.get(
            `${window.BACKEND_URL}docente/users`,
            {
              headers: {
                Authorization: `${sessionStorage.getItem("token")}`,
              },
            }
          );
          console.log(userResponse.data);
          const usuario = userResponse.data.find(
            (user) => user._id === alumno.alu_id
          );
          if (usuario) {
            return { value: usuario._id, label: usuario.name };
          }
          return null;
        });

        const usuarios = await Promise.all(nombresPromises);
        setUsuarios(usuarios.filter(Boolean)); // Eliminar elementos nulos del array
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching cursos:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}docente/pretensiones`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );
        setPretenciones(response.data);
        console.log(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching pretenciones:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    getCursos();
  }, [id]);

  const filteredPretenciones = pretenciones.filter(
    (pretencion) => pretencion.tipo === selectedMateria
  );

  const handleSubmit = async () => {
    try {
      const generarNumeroExpediente = () => {
        const randomNumbers = Math.floor(Math.random() * 1000)
          .toString()
          .padStart(3, "0");
        const year = new Date().getFullYear().toString();

        const materiaLetters = selectedMateria.slice(0, 2).toUpperCase();

        return `00${randomNumbers}-${year}-0-${materiaLetters}`;
      };

      // Crear el expediente con datos únicos
      const expedienteData = {
        curso_id: id,
        grupo_id: null,
        nexpediente: generarNumeroExpediente(),
        cuaderno: [],
        notifica: [],
        avanze: [],
        monto: null,
        created: new Date(),
      };
      const expedienteResponse = await axios.post(
        `${window.BACKEND_URL}docente/expediente/post`,
        expedienteData,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      // Crear el grupo con el ID del expediente
      const grupoData = {
        curso_id: id,
        alumnose: [
          ...selectedAlumnos.alumnos.Demandante.map((alumnoId) => ({
            alumno_id: alumnoId,
            rol_id: "Demandante",
          })),
          ...selectedAlumnos.alumnos.Demandado.map((alumnoId) => ({
            alumno_id: alumnoId,
            rol_id: "Demandado",
          })),
          ...selectedAlumnos.alumnos.Juez.map((alumnoId) => ({
            alumno_id: alumnoId,
            rol_id: "Juez",
          })),
          ...selectedAlumnos.alumnos.Especialista.map((alumnoId) => ({
            alumno_id: alumnoId,
            rol_id: "Especialista",
          })),
        ],
        materia: selectedMateria,
        pretencion: selectedPretencion,
        expe_id: expedienteResponse.data.expedienteId,
      };
      const grupoResponse = await axios.post(
        `${window.BACKEND_URL}docente/cursogrupo/post`,
        grupoData,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );
      console.log(grupoResponse);

      await axios.put(
        `${window.BACKEND_URL}docente/expediente/put/${expedienteResponse.data.expedienteId}`,
        { grupo_id: grupoResponse.data.id },
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      window.history.back();
    } catch (error) {
      console.error("Error creating group:", error);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <h2 className="py-2 pt-3">Crear Grupo</h2>

        <div className="row">
          <div className="col-md-6">
            <div className="form-group ms-2">
              <label
                htmlFor="materia"
                style={{
                  marginRight: "8px",
                  color: "#424242",
                  fontSize: "14px",
                }}
              >
                Materia:
              </label>
              <select
                className="form-select px-2"
                style={{ fontSize: "14px" }}
                id="materia"
                onChange={(e) => setSelectedMateria(e.target.value)}
              >
                <option value="" selected disabled>
                  Seleccione una materia
                </option>
                <option value="CIVIL">Civil</option>
                <option value="CONSTITUCIONAL">CONSTITUCIONAL</option>
                <option value="CONTENCION ADMINISTRATIVA">
                  CONTENCION ADMINISTRATIVA
                </option>
                <option value="FAMILIA CIVIL">FAMILIA CIVIL</option>
                <option value="LABORAL">LABRORAL</option>
              </select>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group ms">
              <label
                htmlFor="pretension"
                style={{
                  marginRight: "8px",
                  color: "#424242",
                  fontSize: "14px",
                }}
              >
                Pretensión:
              </label>
              <select
                className="form-select px-2"
                style={{ fontSize: "14px" }}
                id="pretension"
                onChange={(e) => setSelectedPretencion(e.target.value)}
              >
                <option value="" selected disabled>
                  Seleccione una pretensión
                </option>
                {filteredPretenciones.map((pretencion) => (
                  <option key={pretencion._id} value={pretencion._id}>
                    {pretencion.nombre}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <h2 className="py-2 pt-3"> Seleccione los roles del caso</h2>

        <div className="container-fluid">
          <div className="row">
            {["Demandante", "Demandado", "Juez", "Especialista"].map((rol) => (
              <div key={rol} className="col-sm-3">
                <div className="form-check form-check-inline">
                  <label
                    className="form-check-label p-2 m-0"
                    htmlFor={`inlineCheckbox-${rol}`}
                    style={{ fontSize: "14px" }}
                  >
                    <span className="fw-bold">{rol}</span>
                  </label>
                </div>
                <Select
                  options={usuarios}
                  value={selectedAlumnos.alumnos[rol].map((value) => {
                    const user = usuarios.find((user) => user.value === value);

                    return user
                      ? { value: user.value, label: user.label }
                      : null;
                  })}
                  onChange={(selectedOption) => {
                    const selectedValues = selectedOption.map(
                      (option) => option.value
                    );
                    setSelectedAlumnos((prevState) => ({
                      ...prevState,
                      alumnos: {
                        ...prevState.alumnos,
                        [rol]: selectedValues,
                      },
                    }));
                  }}
                  isMulti
                  isSearchable
                  getOptionValue={(option) => option.value}
                  getOptionLabel={(option) => option.label}
                />
              </div>
            ))}
          </div>
        </div>
        <section>
          <div className="container-fluid">
            <div className="row">
              <div className="col-6 p-0 mb-3 mt-4">
                <Link
                  onClick={handleCancel}
                  type="button"
                  className="btn btn-cancelar px-2"
                >
                  <i className="fa-solid fa-times me-2"></i>
                  Cancelar
                </Link>
              </div>
              <div className="col-6 mb-5 mt-4 d-flex flex-row-reverse">
                <button
                  type="button"
                  className="btn btn-stemis px-2"
                  onClick={handleSubmit}
                >
                  Grabar
                  <i className="fa-solid fa-circle-plus ms-2"></i>
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AddGrupoC;
