import React from "react";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./pages/Layoutdoc";
import Layoutalum from "./pages/Layoutalu";
import Home from "./pages/Home";
import Blogs from "./pages/Blogs";
import Loginusuario from "./pages/loginusuario";
import NoPage from "./pages/NoPage";
import NavBar from "./components/NavBar";

import InfocursoDoc from "./Docente/infodoc_cur";
// Vista para el rol de alumno

import Homealu from "./pages/homealu";

//vistas de docete

// Alumno
import Expediente from "./alumno/expedientes/Expediente";
import CuaderAddNotifi from "./alumno/CuaderAddNotifi";
import CuaderAddEscrito from "./alumno/CuaderAddEscrito";
import MyExperient from "./alumno/layout/MyExperient";
import RepoExperient from "./alumno/layout/RepoExperient";
import Calendar from "./alumno/layout/Calendar";
import Calificaciones from "./alumno/layout/Calificaciones";
import Escritos from "./alumno/Escritos";
import AddEscritos from "./alumno/AddEscritos";
import AddNotifi from "./alumno/AddNotifi";
import Notifica from "./alumno/Notifica";
import Avance from "./alumno/Avance";
import Cursos from "./alumno/cursos/Cursos";
import Demanda from "./alumno/Demanda";
import CuadernoExpe from "./alumno/expedientes/CuadernoExpe";
import ExpeDate from "./alumno/expedientes/ExpeDate";
//perfil
import PerfilT from "./pages/perfil";
import EditPassword from "./pages/editperfil";
import Normatividad from "./alumno/Normatividad";
import EditPhoto from "./pages/editphoto";
//Docente
//Components
import AddRubrica from "./Docente/Components/Rubrica/Addrubrica";
import EditRubrica from "./Docente/Components/Rubrica/EditRubrica";
import Rubricas from "./Docente/Components/Rubrica/rubricas";
import Anuncios from "./Docente/Components/anuncio";
import Salaaudi from "./Docente/Components/salaaudi";

import LibroCalifica from "./Docente/Components/libro_cali";
import Documentos from "./Docente/Components/documentos";
import Detallescurs from "./Docente/Components/detalles_curso";
//Expediente
import EditNotiDoc from "./Docente/Expediente/editnoti";
import ExpedienteDoc from "./Docente/Expediente/expediente";
import Notification from "./Docente/Expediente/notiDoc";
import EditDemanda from "./Docente/Expediente/editDemanda";
import EditNotiCu from "./Docente/Expediente/editNotiCu";
//escritos
import Escrito from "./Docente/Expediente/escrito";
import CuadernoExpeDoc from "./Docente/Expediente/cuaderno";
//Layout
import Estadistica from "./Docente/Layout/estadistica";
import Calendario from "./Docente/Layout/calendario";
import RepoExpeDoc from "./Docente/Layout/repoexpe";
//Grupo
import ListGruop from "./Docente/Grupo/listgroup_doc";
import AddGrupoC from "./Docente/Grupo/add_grupo";
import EditGrupos from "./Docente/Grupo/edit_grupo";

//recuper contraseña
import ForgotPassword from "./Recuperar/ForgotPassword";
import ResetPassword from "./Recuperar/ResetPassword";
//normatividad
import NormatividadDoc from "./Docente/normativa/normativacard";
import NormatDetalles from "./Docente/normativa/normaDetalles";

const ProtectedRoute = ({ }) => {
  const role = sessionStorage.getItem("role");
  if (role === "Docente") {
    return <Layout />;
  } else if (role === "Alumno") {
    return <Layoutalum />;
  } else {
    return <Navigate to={"/login"} replace />;
  }
};

const ProtectedHome = ({ }) => {
  const role = sessionStorage.getItem("role");
  if (role === "Docente") {
    return <Home />;
  } else if (role === "Alumno") {
    return <Homealu />;
  } else {
    return <Navigate to={"/login"} replace />;
  }
};

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Loginusuario />} />
        <Route path="/alumno-ForgotPassword" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/" element={<ProtectedRoute />}>
          <Route index element={<ProtectedHome />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="curso-docente/:id" element={<InfocursoDoc />} />
          <Route path="/lista-grupos/:id" element={<ListGruop />} />
          <Route path="creargrupo/:id" element={<AddGrupoC />} />
          <Route
            path="/Editar-grupo/:id/expediente/:idGrupo"
            element={<EditGrupos />}
          />
          <Route path="/agregar-escrito/:id" element={<Escrito />} />
          <Route path="/agregar-rubrica/:id" element={<AddRubrica />} />
          <Route
            path="/editar-rubrica/:cursoid/:id"
            element={<EditRubrica />}
          />

          <Route
            path="noti-cuaderno/:id/edit-cuaderno/:idnoti"
            element={<EditNotiCu />}
          />
          <Route
            path="Editar-notificacion/:nexpeid/edit/:id"
            element={<EditNotiDoc />}
          />
          <Route path="/estadistica" element={<Estadistica />} />
          <Route
            path="expediente-docente/:id/:idcurso"
            element={<ExpedienteDoc />}
          />
          <Route path="cuadernoedit/:id/:id" element={<CuadernoExpeDoc />} />
          <Route path="libro-calificacion/:id" element={<LibroCalifica />} />
          <Route path="slaaudiencia/:id" element={<Salaaudi />} />
          <Route path="rubricas/:id" element={<Rubricas />} />
          <Route path="Documentos/:id" element={<Documentos />} />
          {/* <Route path="editexpe/:id" element={<EditExpe />} /> */}
          <Route path="detalle-curso/:id" element={<Detallescurs />} />
          <Route path="calendariodoc" element={<Calendario />} />
          <Route path="repositorio-expediente" element={<RepoExpeDoc />} />
          <Route path="Editar-demanda/:id" element={<EditDemanda />} />
          <Route path="Agregar-notificacion/:id" element={<Notification />} />
          <Route path="Agregar-anuncio/:id" element={<Anuncios />} />
          {/* Ruta para el rol de alumno */}
          <Route path="*" element={<NoPage />} />
          <Route path="/navbar" element={<NavBar />} />
          <Route path="/perfil/:id" element={<PerfilT />} />
          <Route path="/editpass/:id" element={<EditPassword />} />
          <Route path="/editPhto/:id" element={<EditPhoto />} />
          {/* --------------------------- */}
          {/* --------------------------- */}
          {/* Rutas para el rol de alumno */}
          {/* principales */}
          <Route path="cursos" element={<Cursos />} />
          <Route path="normatividad" element={<Normatividad />} />
          <Route path="curso-alumno/:id" element={<Cursos />} />
          <Route path="vista_expediente/:id" element={<ExpeDate />} />
          <Route path="expediente/:id" element={<Expediente />} />
          <Route path="/:id/:id" element={<CuadernoExpe />} />
          {/* ingreso de demandas */}
          <Route path="/:id/demanda" element={<Demanda />} />
          {/*  expedientes */}
          <Route path="my_expediente" element={<MyExperient />} />
          <Route path="repo_expediente" element={<RepoExperient />} />
          <Route path="calendario" element={<Calendar />} />
          {/* Calificaciones */}
          <Route path="calificaciones" element={<Calificaciones />} />
          {/* Escritos */}
          <Route path="/:id/escritos" element={<Escritos />} />
          <Route path="/:id/add_escritos" element={<AddEscritos />} />

          {/* Notificaciones */}
          <Route path="/:id/notifi" element={<Notifica />} />
          <Route path="/:id/add_notifi" element={<AddNotifi />} />
          {/* Cuaderno  */}
          <Route
            path="/:id/:cuader_id/add_escritos"
            element={<CuaderAddEscrito />}
          />
          <Route
            path="/:id/:cuader_id/add_notifi"
            element={<CuaderAddNotifi />}
          />
          {/* Avance */}
          <Route path="/:id/avance" element={<Avance />} />

          <Route path="/normatividad/uni" element={<NormatividadDoc />} />
          <Route path="/Normadetalles/:slug" element={<NormatDetalles />} />


        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
