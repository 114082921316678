import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const EditPhoto = () => {
  const { id } = useParams();
  const [newPhoto, setNewPhoto] = useState(null);
  const [previewPhoto, setPreviewPhoto] = useState(null);

  const handlePhotoChange = (event) => {
    const selectedPhoto = event.target.files[0];
    setNewPhoto(selectedPhoto);
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewPhoto(reader.result);
    };
    reader.readAsDataURL(selectedPhoto);
  };

  const handleCancel = () => {
    window.history.back();
  };

  const handleSubmit = async () => {
    try {
      // Verificar si hay una nueva foto seleccionada
      if (!newPhoto) {
        console.error("No se ha seleccionado una nueva foto.");
        return;
      }

      // Crear un objeto FormData para enviar la foto al servidor
      const formData = new FormData();
      formData.append("photo", newPhoto);

      // Realizar la solicitud PUT al servidor para actualizar la foto del usuario
      const response = await axios.put(
        `${window.BACKEND_URL}users/${id}/photo`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        window.history.back();
      } else {
        console.error("Error al actualizar la foto:", response.statusText);
      }
    } catch (error) {
      console.error("Error al procesar la solicitud:", error.message);
    }
  };

  return (
    <div className="container">
      <h1 className="my-4">Editar Foto</h1>
      <div className="mb-3">
        <label htmlFor="photoInput" className="form-label">
          Seleccionar nueva foto:
        </label>
        <input
          className="form-control"
          type="file"
          id="photoInput"
          accept="image/*"
          onChange={handlePhotoChange}
        />
      </div>
      {previewPhoto && (
        <div className="mb-3">
          <h3>Vista previa de tu foto:</h3>
          <img
            src={previewPhoto}
            alt="Vista previa de la foto"
            className="img-thumbnail"
            style={{ maxWidth: "200px" }}
          />
        </div>
      )}

      <div className="d-grid gap-2 d-md-flex justify-content-md-between">
        <button
          onClick={handleCancel}
          className="btn btn-cancelar me-md-2 mb-2 mb-md-0"
        >
          Cancelar
        </button>
        <button onClick={handleSubmit} className="btn btn-stemis">
          Guardar
        </button>
      </div>
    </div>
  );
};

export default EditPhoto;
