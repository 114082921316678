import { Page, Text, View, Document, Image, pdf } from "@react-pdf/renderer";

import JsBarcode from "jsbarcode";

const generateBarcode = (data) => {
  const canvas = document.createElement("canvas");
  // Agregar el canvas al DOM, aunque no se necesite mostrarlo
  document.body.appendChild(canvas);

  // Llamar a JsBarcode para generar el código de barras en el canvas
  JsBarcode(canvas, data, { format: "CODE128" });

  // Esperar un breve período de tiempo para asegurar que el código de barras se genere completamente
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      // Convertir el contenido del canvas a un Data URL
      const dataURL = canvas.toDataURL("image/png");
      // Remover el canvas del DOM
      document.body.removeChild(canvas);
      // Resolver la promesa con el Data URL del código de barras generado
      resolve(dataURL);
    }, 100); // Esperar 100 milisegundos antes de obtener el Data URL
  });
};

export default async function ExpedientePdf({
  data_expe,
  data_demanda,
  fecha,
  destinatario,
  numnotifica,
}) {
  const pageStyles = {
    flexDirection: "row",
    backgroundColor: "#fff",
    padding: 30,
  };
  const sectionStyles = {
    flexGrow: 1,
    marginHorizontal: 5,
  };
  const sectionStyles2 = {
    flexGrow: 1,
  };
  const titleStyles = {
    fontSize: 12,
    marginBottom: 5,
    textAlign: "center", // Centrado
    borderBottom: "1px solid black", // Línea de subrayado
  };
  const titleStyles2 = {
    fontSize: 15,
    marginBottom: 5,

    textAlign: "center", // Centrado
  };
  const textitemlStyles = {
    fontWeight: "bold",
    fontSize: "8px",
    width: 170,
  };
  const textitemlStyles2 = {
    fontWeight: "bold",
    fontSize: "8px",
    width: 100,
    marginBottom: 2,
  };
  const textitemlStyles3 = {
    fontWeight: "bold",
    fontSize: "8px",
  };
  const itemStyles = {
    flexDirection: "row",
    justifyContent: "space-between", // Alinear los elementos a lo largo del eje principal con espacio entre ellos

    // borderBottom: "10px"
  };
  const itemStyles2 = {
    flexDirection: "row",

    alignItems: "flex-start",
    borderBottom: "1px solid black",
  };
  const itemStyles3 = {
    flexDirection: "row",

    alignItems: "flex-start",

    marginTop: 4,
  };
  const itemElementStyles = {
    fontWeight: "bold",
    fontSize: "8px",
    width: 95,
  };
  const itemElementStyles2 = {
    fontWeight: "bold",
    fontSize: "8px",
  };

  const Demandantes = data_expe.alumnose
    .filter((item) => item.rol_id === "Demandante")
    .map((item) => item.nombre)
    .join(", ");

  const Demandados = data_expe.alumnose
    .filter((item) => item.rol_id === "Demandado")
    .map((item) => item.nombre)
    .join(", ");

  const Juezes = data_expe.alumnose
    .filter((item) => item.rol_id === "Juez")
    .map((item) => item.nombre)
    .join(", ");

  const Especialistas = data_expe.alumnose
    .filter((item) => item.rol_id === "Especialista")
    .map((item) => item.nombre)
    .join(", ");

  const pdfDocFromJSX = (
    <Document>
      <Page size="A4" style={pageStyles}>
        <View style={sectionStyles}>
          {/* Inicio  */}
          {/* Data inicial  */}
          <View style={itemStyles}>
            <Text style={{ ...textitemlStyles2, width: 300 }}></Text>

            <Image
              src={`${window.BACKEND_URL}uploads/logo.png`}
              // src="https://upload.wikimedia.org/wikipedia/commons/5/58/Poder_Judicial_del_Peru.png"
              style={{
                paddingLeft: 15,
                maxWidth: "70px",
                alignItems: "end",
                maxHeight: "70px",
              }}
            />
          </View>
          <View style={itemStyles}>
            <Text style={{ ...textitemlStyles2, width: 300 }}>
              PODER JUDICIAL DEL PERU
            </Text>

            <Text style={textitemlStyles3}>{data_expe.data_expe}</Text>
          </View>
          <View style={itemStyles}>
            <Text style={{ ...textitemlStyles2, width: 300 }}>
              CORTE SUPERIOR DE JUSTICIA
            </Text>

            <Text style={textitemlStyles3}>.</Text>
          </View>
          <View style={itemStyles}>
            <Text style={{ ...textitemlStyles2, width: 300 }}>
              {data_demanda?.distrito}
            </Text>

            <Text style={textitemlStyles3}>.</Text>
          </View>
          <View style={itemStyles}>
            <Text
              style={{
                ...textitemlStyles2,
                width: 300,
                marginBottom: 10,
              }}
            >
              {data_demanda?.sede}
            </Text>

            <Text style={textitemlStyles3}>.</Text>
          </View>
          {/* Titulos  */}
          <Text style={titleStyles2}>CEDULA ELECTRONICA</Text>
          <Text style={titleStyles2}>EXPEDIENTE JUDICIAL ELECTRÓNICO</Text>

          <Image
            src={generateBarcode("stemis.com.pe")}
            style={{
              minHeight: "10%",
              maxHeight: "10%",
              minWidth: "90%",
              maxWidth: "90%",
              paddingLeft: 40,
            }}
          />

          {/* Notificacion */}
          <Text style={titleStyles}>NOTIFICACION N° {numnotifica}</Text>
          <View style={sectionStyles2}>
            <View style={itemStyles}>
              <Text style={textitemlStyles2}>EXPEDIENTE</Text>
              <Text style={textitemlStyles}>{data_expe.nexpediente}</Text>
              <Text style={textitemlStyles2}>JUZGADO</Text>
              <Text style={textitemlStyles}>{data_demanda?.juzgado}</Text>
            </View>

            <View style={itemStyles}>
              <Text style={textitemlStyles2}>JUEZ</Text>
              <Text style={textitemlStyles}>{Juezes}</Text>
              <Text style={textitemlStyles2}>ESPECIALISTA LEGAL</Text>
              <Text style={textitemlStyles}>{Especialistas}</Text>
            </View>

            <View style={itemStyles2}>
              <Text style={itemElementStyles}>MATERIA</Text>
              <Text style={itemElementStyles2}> {data_expe.materia}</Text>
            </View>

            <View style={itemStyles3}>
              <Text style={itemElementStyles}>DEMANDANTE</Text>
              <Text style={itemElementStyles2}>: {Demandantes}</Text>
            </View>
            <View style={itemStyles2}>
              <Text style={itemElementStyles}>DEMANDADO</Text>
              <Text style={itemElementStyles2}>: {Demandados}</Text>
            </View>
            <View style={itemStyles3}>
              <Text style={itemElementStyles}>DESTINATARIO</Text>
              <Text style={itemElementStyles2}>
                : {destinatario ? Demandantes : Demandados}
              </Text>
            </View>
            <View style={{ ...itemStyles3, marginTop: 25 }}>
              <Text style={itemElementStyles}>DIRECCION</Text>
              <Text style={itemElementStyles2}> : info@stemis.com.pe </Text>
            </View>

            <Text style={{ ...itemElementStyles, width: 300, marginTop: 25 }}>
              Se adjunta Resolución de fecha {fecha}
            </Text>
          </View>
          {/* <View style={totalStyles}>
            <Text style={labelStyles}>9 DE AGOSTO DE 2022:</Text>
          </View> */}
        </View>
      </Page>
    </Document>
  );

  // Convertir el documento PDF a un Blob
  const pdfBlob = await pdf(pdfDocFromJSX).toBlob();

  return pdfBlob;
}
