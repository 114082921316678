import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const Rubricas = () => {
  const { id } = useParams();
  const [rubricas, setRubricas] = useState([]);
  const [cargando, setCargando] = useState(true);

  const obtenerRubricas = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}docente/curso/rubrica/${id}`
      );
      setRubricas(response.data);
      setCargando(false);
    } catch (error) {
      console.error("Error al obtener las rubricas:", error);
      setCargando(false);
    }
  };

  useEffect(() => {
    obtenerRubricas();
  }, [id]);

  const handleDeleteRubrica = async (rubricaId) => {
    try {
      await axios.delete(
        `${window.BACKEND_URL}docente/rubricadelete/${id}/${rubricaId}`
      );
      // Actualizar el estado para reflejar la eliminación
      setRubricas(
        rubricas.filter((rubrica) => rubrica.rubrica_id !== rubricaId)
      );
      Swal.fire("Se elimino Correctamente", "", "success");
      obtenerRubricas();
    } catch (error) {
      console.error("Error al eliminar la rubrica:", error);
    }
  };

  const exportRubrica = (rubrica) => {
    const json = JSON.stringify(rubrica, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${rubrica.titulo}.json`;
    link.click();
  };

  const importRubrica = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const importedRubrica = JSON.parse(e.target.result);

        // Send the imported rubric to the backend
        await axios.put(
          `${window.BACKEND_URL}docente/curso/${id}/importarrubrica`,
          importedRubrica
        );

        Swal.fire("Rúbrica importada correctamente", "", "success");
        obtenerRubricas(); // Actualizar las rúbricas después de importar
      } catch (error) {
        Swal.fire("Error al importar la rúbrica", "", "error");
        console.error("Error al importar la rúbrica:", error);
      }
    };
    reader.readAsText(file);
  };
  return (
    <>
      <section>
        <div className="row p-3">
          <div className="col-sm-9 col-md-6"></div>
          <div className="col-sm-9 col-md-6 d-flex justify-content-end py-sm-0 py-2">
            <label className="btn btn-secondary me-2">
              Importar Rúbrica
              <input
                type="file"
                accept=".json"
                onChange={importRubrica}
                style={{ display: "none" }}
              />
            </label>
            <Link
              to={`/agregar-rubrica/${id}`}
              type="button"
              className="btn btn-stemis"
              style={{ textAlign: "right" }}
            >
              Crear Rubrica
              <i
                className="fa-solid fa-circle-plus"
                style={{ width: "33px", height: "auto" }}
              ></i>
            </Link>
          </div>
        </div>
      </section>
      {cargando ? (
        <div className="text-center mt-5">
          <i className="fa-solid fa-spinner fa-spin icon fs-1 bg-stemis"></i>
          <p>Cargando Rubrica...</p>
        </div>
      ) : (
        <section className="table-responsive ">
          <table className="table table-striped table-hover table-light">
            <thead>
              <tr className="text-center justify-content-center">
                <th scope="col" className="text-center">
                  N° de orden
                </th>
                <th scope="col" className="text-center">
                  Nombre
                </th>
                <th scope="col" className="text-center">
                  Criterios
                </th>
                <th scope="col" className="text-center">
                  Calificaciones
                </th>
                <th scope="col" className="text-center">
                  Acciones
                </th>
              </tr>
            </thead>
            <tbody className="table-group-divider">
              {rubricas.map((rubrica, index) => (
                <tr key={rubrica.rubrica_id}>
                  <th scope="row" className="text-center">
                    {index + 1}
                  </th>
                  <td className="text-center">{rubrica.titulo}</td>
                  <td className="text-center">{rubrica.criterios.length}</td>
                  <td className="text-center">
                    {obtenerTotalNotaRubrica(rubrica)}
                  </td>
                  <td className="text-center">
                    <Link
                      className="btn btn-stemis me-1"
                      to={`/editar-rubrica/${id}/${rubrica.rubrica_id}`}
                    >
                      <i className="fa fa-edit" aria-hidden="true"></i>
                    </Link>
                    <button
                      className="btn btn-stemis me-1"
                      onClick={() => handleDeleteRubrica(rubrica.rubrica_id)}
                    >
                      <i className="fa fa-trash" aria-hidden="true"></i>
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={() => exportRubrica(rubrica)}
                    >
                      Exportar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      )}
    </>
  );
};

const obtenerTotalNotaRubrica = (rubrica) => {
  let totalNota = 0;
  rubrica.criterios.forEach((criterio) => {
    let notaMasAlta = 0;
    criterio.descripciones.forEach((descripcion) => {
      const nota = parseFloat(descripcion.nota);
      if (!isNaN(nota) && nota > notaMasAlta) {
        notaMasAlta = nota;
      }
    });
    totalNota += notaMasAlta;
  });
  // Redondear a dos decimales
  return Math.round(totalNota * 100) / 100;
};

export default Rubricas;
