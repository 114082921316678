import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const EditNotiDoc = () => {
  const { nexpeid, id } = useParams();
  const [notificacion, setNotificacion] = useState(null);
  const [sumilla, setSumilla] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [Links, setLink] = useState("");
  const [file, setFile] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}docente/expediente/get/${nexpeid}/notifica/${id}`
        );
        const data = response.data;
        setNotificacion(data);
        setSumilla(data.sumilla || "");
        setObservaciones(data.observaciones || "");
        setLink(data.grab_audiencia || "");
      } catch (error) {
        console.error("Error al obtener el expediente", error);
      }
    };
    fetchData();
  }, [nexpeid, id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("sumilla", sumilla);
    formData.append("observaciones", observaciones);
    formData.append("grab_audiencia", Links);

    if (file) {
      formData.append("file", file);
    }
    try {
      await axios.put(
        `${window.BACKEND_URL}docente/expediente/put/${nexpeid}/notifica/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      Swal.fire("Expediente actualizado correctamente", "", "success");
      window.history.back();
    } catch (error) {
      console.error("Error al actualizar el expediente", error);
      alert("Error al actualizar el expediente");
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleCancel = () => {
    window.history.back();
  };

  if (!notificacion) {
    return <p>Cargando...</p>;
  }

  return (
    <section>
      <div className="container-fluid">
        <h2>Editar {notificacion.tipo}</h2>
        <form
          className="row g-3 needs-validation mt-4"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
          noValidate
        >
          <div className="row">
            <div className="col-md-8 px-4">
              <div className="row">
                <div className="col-6 col-md-4 mt-2">
                  <p className="text-left mt-2">Notificación</p>
                </div>
                <div className="col-md-8 p-0 mt-2">
                  <input
                    type="text"
                    value={sumilla}
                    onChange={(e) => setSumilla(e.target.value)}
                    className="form-control"
                    name="sumilla"
                    required
                  />
                  <div className="invalid-feedback">Campo Obligatorio.</div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-6 col-md-4">
                  <p>Observaciones:</p>
                </div>
                <div className="col-md-8 p-0 mt-2">
                  <textarea
                    value={observaciones}
                    onChange={(e) => setObservaciones(e.target.value)}
                    className="form-control"
                    name="observaciones"
                    style={{
                      border: "1px solid rgb(231, 231, 231)",
                      height: "100px",
                      fontSize: "14px",
                      color: "#4b4b4b",
                    }}
                  ></textarea>
                </div>
              </div>
              {notificacion.grab_audiencia && (
                <div className="row mt-2">
                  <div className="col-6 col-md-4">
                    <p>Link de la audiencia :</p>
                  </div>
                  <div className="col-md-8 p-0 mt-2">
                    <textarea
                      value={Links}
                      onChange={(e) => setLink(e.target.value)}
                      className="form-control"
                      name="Links"
                    ></textarea>
                  </div>
                </div>
              )}
            </div>
            <div className="row mt-3">
              <div className="col-md-12 px-2">
                {notificacion.file && (
                  <a
                    className="p-3 rounded btn"
                    target="_blank"
                    href={`${window.BACKEND_URL}${notificacion.file}`}
                    download
                  >
                    Archivo subido anteriormente
                    <i className="fa-solid fa-file-import mx-1"></i>
                  </a>
                )}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12 px-2">
                <input type="file" name="file" onChange={handleFileChange} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6 p-0 mb-3 mt-4">
              <Link
                onClick={handleCancel}
                type="button"
                className="btn btn-cancelar px-2"
              >
                <i className="fa-solid fa-times me-2"></i>
                Cancelar
              </Link>
            </div>
            <div className="col-6 mb-5 mt-4 d-flex flex-row-reverse">
              <button type="submit" className="btn btn-stemis px-5">
                Grabar
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default EditNotiDoc;
