import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const NormatDetalles = () => {
  const [normativa, setNormativa] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { slug } = useParams();

  const createMarkup = () => {
    return { __html: normativa.body };
  };

  useEffect(() => {
    const fetchNormativa = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}normatividad/slug/${slug}`
        );
        setNormativa(response.data);
        setIsLoading(false); // Cambiar el estado de carga a falso cuando se complete la solicitud
      } catch (error) {
        console.error("Error fetching normativa:", error);
        setIsLoading(false); // Cambiar el estado de carga a falso en caso de error
      }
    };

    fetchNormativa();
  }, [slug]);

  return (
    <div className="m-0 p-0">
      {isLoading && (
        <div className="text-center mt-5">
          <i className="fa-solid fa-spinner fa-spin icon fs-1 bg-stemis"></i>
          <p>Cargando normativa...</p>
        </div>
      )}
      {!isLoading && normativa && (
        <div className="p-0 m-0">
          <div
            data-speed=".8"
            style={{
              display: "flex",
              justifyContent: "center", // Centrar horizontalmente
            }}
          >
            <img
              src={`${window.BACKEND_NORMATIVA}uploads/${normativa.foto_port}`}
              className="card-img-top shadow"
              alt={normativa.titulo}
              style={{
                width: "96%",
                height: "17rem",
                maskImage: `
                linear-gradient(to top, transparent 2%, black 35%, black 70%,transparent )
                `, // Aplicar mask-image combinando ambos gradientes
              }}
            />
          </div>

          <section
            className="pt-6 pt-md-8 pb-12"
            style={{
              backgroundImage: 'url("/img/logostemisplomo.png")',
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center", // Mover la imagen hacia la derecha y centrar verticalmente
            }}
          >
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-11">
                  <div className="col-12 text-center mb-3">
                    <a
                      style={{
                        backgroundColor: "#259995",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      className="p-3 rounded"
                      target="_blank"
                      href={`${window.BACKEND_URL}uploads/${normativa.archivopdf}`}
                      download={`${window.BACKEND_URL}uploads/${normativa.archivopdf}`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-download"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                      </svg>{" "}
                      Norma completa
                    </a>
                  </div>
                  <div
                    className="px-0 m-0"
                    style={{
                      height: "100%",
                      width: "103%",
                    }}
                  >
                    <iframe
                      srcDoc={`
                                <style>
                                    body {
                                        font-family: Arial, sans-serif;
                                    }
                                    div {
                                        text-align: justify;
                                    }
                                </style>
                                <div>${normativa.body}</div>
                            `}
                      style={{
                        border: "none",
                        width: "100%",
                        height: "72vh",
                      }}
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
};

export default NormatDetalles;
