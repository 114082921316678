import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
const Salaaudi = () => {
  const { id } = useParams();
  const [username] = useState(sessionStorage.getItem("userName"));
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [cursos, setCursos] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const getCursos = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${window.BACKEND_URL}docente/cursos_datatitulounico/${id}`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setCursos(response.data);
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const audiencia = {
        curso: cursos[0].informacion_titulo[0].nombre,
        user: username,
        acto: "Audicenica",
        tipo_audi: e.target.tipo_audi.value,
        fechaHoraInicio: startDate.toISOString(),
        fechaHoraFin: endDate.toISOString(),
        link_audi: e.target.link_audi.value,
      };

      // Envía solo el objeto 'audiencia' en el array 'audiencia' existente
      const response = await axios.put(
        `${window.BACKEND_URL}docente/curso/audiencia/${id}`,
        audiencia,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );
      console.log(response.data, "response");
      Swal.fire("Audiencia creada ", "", "success");
      e.target.reset();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCursos();
  }, []);

  return (
    <>
      <section className="col-md-8 ">
        <div className="pb-3 ">
          <h2>Programar Audiencia</h2>
        </div>

        {loading ? (
          <div className="text-center mt-5">
            <i className="fa-solid fa-spinner fa-spin icon fs-1 bg-stemis"></i>
            <p>Cargando Audiencia ...</p>
          </div>
        ) : (
          <form className="ms-2" onSubmit={handleSubmit}>
            <div className="row  ">
              <div className="col-4 mt-2">
                <label htmlFor="inputEmail3">
                  <p>
                    Tipo de Audiencia
                    <text className="text-danger">*</text>
                  </p>
                </label>
              </div>
              <div className="col-8 p-0 mt-2">
                <select
                  id="tipo_audi"
                  className="form-select  border-3  bg-light"
                  name="tipo_audi"
                  required
                >
                  <option value="" disabled>
                    Seleccione un tipo de Audiencia
                  </option>

                  <option value="Audiencia Preliminar">
                    Audiencia Preliminar
                  </option>
                  <option value="Audiencia Única">Audiencia Única</option>
                  <option value="Audiencia de Pruebas">
                    Audiencia de Pruebas
                  </option>
                  <option value="Audiencia Especial">Audiencia Especial</option>
                  <option value="Vista de la Causa">Vista de la Causa</option>
                </select>
              </div>
            </div>
            <div className="row  ">
              <div className="col-4 mt-2">
                <label htmlFor="inputEmail3">
                  <p>
                    Fecha/Hora de Inicio
                    <text className="text-danger">*</text>
                  </p>
                </label>
              </div>
              <div className="col-8 p-0 mt-2">
                <div className="datepicker-container">
                  <DatePicker
                    className="form-control  border-3  bg-light"
                    selected={startDate}
                    onChange={handleStartDateChange}
                    showTimeSelect
                    dateFormat="Pp"
                    placeholderText="Seleccione una fecha"
                    wrapperClassName="datepicker-wrapper"
                  />
                </div>
              </div>
            </div>
            <div className="row  ">
              <div className="col-4 mt-2">
                <label htmlFor="inputEmail3">
                  <p>
                    Fecha/Hora de fin
                    <text className="text-danger">*</text>
                  </p>
                </label>
              </div>
              <div className="col-8 p-0 mt-2">
                <div className="datepicker-container">
                  <DatePicker
                    className="form-control  border-3  bg-light"
                    selected={endDate}
                    onChange={handleEndDateChange}
                    showTimeSelect
                    dateFormat="Pp"
                    placeholderText="Seleccione una fecha"
                    wrapperClassName="datepicker-wrapper"
                  />
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-4 mt-2">
                <label htmlFor="inputEmail3">
                  <p>
                    Link de la Audiencia
                    <text className="text-danger">*</text>
                  </p>
                </label>
              </div>
              <div className="col-8 p-0 mt-2">
                <input
                  className="form-control  border-3  bg-light "
                  placeholder="http//linkdeejemplo.com"
                  name="link_audi"
                  id="link_audi"
                  type="link"
                />
                <div className="invalid-feedback">Campo Obligatorio.</div>
              </div>
            </div>

            <div className="row align-content-center text-end">
              <div className="col-12 py-4 ">
                <button className="btn btn-stemis mb-n5 " type="submit">
                  Grabar
                </button>
              </div>
            </div>
          </form>
        )}
      </section>
    </>
  );
};

export default Salaaudi;
