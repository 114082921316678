import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const AddRubrica = () => {
  const { id } = useParams();
  const guardarBtnRef = useRef(null);
  const [tableData, setTableData] = useState([
    {
      criterio: "",
      descripciones: [""],
      calificaciones: [0],
    },
  ]);

  const addRow = () => {
    setTableData((prevTableData) => [
      ...prevTableData,
      {
        criterio: "",
        descripciones: ["", "", ""],
        calificaciones: [0, 0, 0],
      },
    ]);
  };

  const deleteRow = (index) => {
    setTableData((prevTableData) =>
      prevTableData.filter((_, rowIndex) => rowIndex !== index)
    );
  };

  const deleteCell = (rowIndex, cellIndex) => {
    setTableData((prevTableData) =>
      prevTableData.map((rowData, index) => {
        if (index === rowIndex) {
          return {
            ...rowData,
            descripciones: rowData.descripciones.filter(
              (_, descIndex) => descIndex !== cellIndex
            ),
            calificaciones: rowData.calificaciones.filter(
              (_, calIndex) => calIndex !== cellIndex
            ),
          };
        }
        return rowData;
      })
    );
  };

  const updateTotal = (rowIndex, cellIndex, value) => {
    setTableData((prevTableData) =>
      prevTableData.map((rowData, index) => {
        if (index === rowIndex) {
          return {
            ...rowData,
            calificaciones: rowData.calificaciones.map((cal, calIndex) =>
              calIndex === cellIndex
                ? value === ""
                  ? ""
                  : parseFloat(value)
                : cal
            ),
            descripciones: rowData.descripciones.map((desc, descIndex) =>
              descIndex === cellIndex ? desc : desc
            ),
          };
        }
        return rowData;
      })
    );
  };

  const addColumn = (rowIndex) => {
    setTableData((prevTableData) =>
      prevTableData.map((rowData, index) => {
        if (index === rowIndex) {
          return {
            ...rowData,
            descripciones: [...rowData.descripciones, ""],
            calificaciones: [...rowData.calificaciones, 0],
          };
        }
        return rowData;
      })
    );
  };

  const sendDataToBackend = async (data) => {
    try {
      await axios.put(`${window.BACKEND_URL}docente/curso/${id}/rubrica`, data);
      Swal.fire(`Rúbrica creada correctamente `, "", "success");
      window.history.back();
    } catch (error) {
      console.error("Error al enviar los datos:", error);
    }
  };

  useEffect(() => {
    const saveRubrica = () => {
      const tituloRubrica = document.getElementById("titulo_rubi").value;
      if (!tituloRubrica.trim()) {
        Swal.fire(
          `Por favor, ingresa un título para la rúbrica `,
          "",
          "warning"
        );
        return;
      }

      const rubricaData = {
        titulo: tituloRubrica,
        criterios: tableData.map((rowData) => ({
          criterio: rowData.criterio,
          descripciones: rowData.descripciones.map((descripcion, index) => ({
            descripcion: descripcion,
            nota: rowData.calificaciones[index],
          })),
        })),
      };

      // Verificar la suma total de puntos
      const totalPuntos = rubricaData.criterios.reduce((total, criterio) => {
        const maxNota = criterio.descripciones.reduce(
          (max, desc) => Math.max(max, desc.nota),
          0
        );
        return total + maxNota;
      }, 0);

      if (totalPuntos > 20) {
        Swal.fire("La suma total de puntos no puede exceder 20", "", "warning");
        return;
      }

      sendDataToBackend(rubricaData);
    };

    if (guardarBtnRef.current) {
      guardarBtnRef.current.addEventListener("click", saveRubrica);
    }

    return () => {
      if (guardarBtnRef.current) {
        guardarBtnRef.current.removeEventListener("click", saveRubrica);
      }
    };
  }, [tableData]);

  const handleCancelar = () => {
    window.history.back();
  };

  return (
    <>
      <section>
        <div className="container-fluid m-2">
          <h2 className="rubrica-title">Título de la Rubrica</h2>
          <input
            type="text"
            placeholder="Ingresa tu texto aquí"
            className=" rounded border border-3"
            name="titulo_rubi"
            id="titulo_rubi"
          />
        </div>
        <div className="container">
          <table className="table table-bordered">
            <tbody>
              {tableData.map((rowData, rowIndex) => (
                <tr key={rowIndex}>
                  <td>
                    <textarea
                      type="text"
                      rows="4"
                      value={rowData.criterio}
                      onChange={(e) => {
                        const newTableData = [...tableData];
                        newTableData[rowIndex].criterio = e.target.value;
                        setTableData(newTableData);
                      }}
                      style={{
                        resize: "none",
                        height: "auto",
                      }}
                      className="form-control required"
                    />
                  </td>
                  {rowData.descripciones.map((descripcion, cellIndex) => (
                    <td key={cellIndex}>
                      <textarea
                        className="form-control required"
                        type="text"
                        rows="4"
                        value={descripcion}
                        onChange={(e) => {
                          const newTableData = tableData.map(
                            (prevRowData, prevRowIndex) =>
                              prevRowIndex === rowIndex
                                ? {
                                    ...prevRowData,
                                    descripciones:
                                      prevRowData.descripciones.map(
                                        (prevDesc, prevDescIndex) =>
                                          prevDescIndex === cellIndex
                                            ? e.target.value
                                            : prevDesc
                                      ),
                                  }
                                : prevRowData
                          );
                          setTableData(newTableData);
                        }}
                      />
                      <br />
                      <div className="row">
                        <div className="col">
                          <button
                            className="btn btn-danger"
                            onClick={() => deleteCell(rowIndex, cellIndex)}
                          >
                            <i className="fas fa-trash-alt"></i>
                          </button>
                        </div>
                        <div className="col">
                          <div className="col">
                            <input
                              type="number"
                              className="form-control"
                              value={rowData.calificaciones[cellIndex]}
                              min="0"
                              max="5"
                              step="0.10" // Permitir decimales con un incremento de 0.1
                              onChange={(e) =>
                                updateTotal(rowIndex, cellIndex, e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </td>
                  ))}
                  <td>
                    <br />
                    <button className="btn" onClick={() => addColumn(rowIndex)}>
                      <i className="fas fa-plus"></i>
                    </button>
                  </td>
                  <td>
                    <br />
                    <button
                      className="btn btn-primary"
                      onClick={() => deleteRow(rowIndex)}
                    >
                      <i className="fas fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button className="btn btn-stemis" onClick={addRow}>
            <i className="fas fa-plus"></i> Agregar Criterio
          </button>
        </div>
        <div className="container d-flex justify-content-end">
          <button className="btn btn-cancelar me-2" onClick={handleCancelar}>
            Cancelar
          </button>
          <button
            id="guardarBtn"
            ref={guardarBtnRef}
            type="submit"
            className="btn btn-stemis"
          >
            Guardar
          </button>
        </div>
      </section>
    </>
  );
};

export default AddRubrica;
