import React from "react";
import axios from "axios";
import $ from "jquery";
import "datatables.net";
import "datatables.net-bs5";
import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

function RepoExperient() {
  const [loading, setLoading] = useState(true);

  const tableRef = useRef(null);

  const [expediente, setExpediente] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}alumno/expediente/repos/get`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      let alumnoIdsSet = new Set();
      response.data.forEach((data) => {
        data.datagrupo.forEach((grupo) => {
          grupo.alumnose.forEach((alumno) => {
            alumnoIdsSet.add(alumno.alumno_id);
          });
        });
      });
      response.data.forEach((data) => {
        data.datacurso.forEach((grupo) => {
          alumnoIdsSet.add(grupo.docente_id);
        });
      });
      let alumnoIds = Array.from(alumnoIdsSet);

      const users = await axios.get(
        `${window.BACKEND_URL}alumno/users/get/ids`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
          params: {
            userIds: alumnoIds,
          },
        }
      );
      const allUsersAlumno = [];
      const allUsersDocente = [];
      const otrosRoles = [];
      users.data.forEach((user) => {
        if (user.role === "Docente") {
          allUsersDocente.push(user);
        } else if (user.role === "Alumno") {
          allUsersAlumno.push(user);
        } else {
          otrosRoles.push(user);
        }
      });

      let pretencioness = new Set();
      response.data.forEach((data) => {
        data.datagrupo.forEach((grupo) => {
          pretencioness.add(grupo.pretencion);
        });
      });

      let dataspretencionIds = Array.from(pretencioness);

      const pretenciones = await axios.get(
        `${window.BACKEND_URL}alumno/pretencion/getids`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
          params: {
            Ids: dataspretencionIds,
          },
        }
      );

      const allPretencion = pretenciones.data;

      const promisess = response.data.map(async (expediente) => {
        const updatedDataGrupo = await Promise.all(
          expediente.datagrupo.map(async (grupo) => {
            // Obtener los nombres de los alumnos

            const alumnosPromises = grupo.alumnose.map(async (alumno) => {
              const usuario = allUsersAlumno.find(
                (data) => data._id === alumno.alumno_id
              );

              if (usuario) {
                alumno.nombre = usuario.name;
              } else {
                console.error(
                  "Usuario no encontrado para el ID:",
                  alumno.alumno_id
                );
              }
            });

            await Promise.all(alumnosPromises);

            let pretensionData = {}; // Inicializa pretensionData fuera del bloque if

            const pretencion = allPretencion.find(
              (data) => data._id === grupo.pretencion
            );

            if (pretencion) {
              pretensionData = pretencion; // Asigna todos los datos de la pretensión si se encuentra
            } else {
              console.error(
                "Pretensión no encontrada para el grupo:",
                grupo.pretencion
              );
              // Otra acción si la pretensión no se encuentra
            }

            return {
              ...grupo,
              datapretension: pretensionData,
            };
          })
        );
        const updatedDataCursoPromises = expediente.datacurso.map(
          async (data) => {
            const docente = allUsersDocente.find(
              (user) => user._id === data.docente_id
            );

            if (docente) {
              data.namedocente = docente.name;
            } else {
              console.error(
                "Usuario no encontrado para el ID:",
                data.docente_id
              );
            }

            return data;
          }
        );

        const updatedDataCurso = await Promise.all(updatedDataCursoPromises);

        return {
          ...expediente,
          datagrupo: updatedDataGrupo,
          datacurso: updatedDataCurso,
        };
      });

      // Esperar a que todas las solicitudes se completen
      const updatedExpedientess = await Promise.all(promisess);

      setExpediente(updatedExpedientess);
    } catch (error) {
      console.error("Error fetching turnos:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!loading && tableRef.current) {
      const dataTable = $(tableRef.current).DataTable({
        searching: true,
        paging: true,
        lengthChange: true,
        language: {
          search: "Buscar:",
          zeroRecords: "No se encontraron resultados",
          info: "Mostrando _START_ a _END_ de _TOTAL_ Expedientes",
          infoEmpty: "Mostrando 0 a 0 de 0 Expedientes",
          infoFiltered: "(filtrado de _MAX_ Expedientes totales)",
          paginate: {
            first: "Primero",
            last: "Último",
            next: "Siguiente",
            previous: "Anterior",
          },
          lengthMenu: " _MENU_ Expedientes",
        },
      });

      return () => {
        // Destruir la tabla de DataTables al desmontar el componente para evitar problemas de memoria
        dataTable.destroy();
      };
    }
  }, [loading]);

  return (
    <>
      <div className="container-fluid ">
        <h2 className="py-2 pt-3">Repositorio de Expedientes </h2>

        <div className="card-body">
          {loading ? (
            <div className="text-center">
              <i className="fa-solid fa-spinner fa-spin icon fs-1 bg-stemis"></i>
              <p>Cargando Repositorio de Expedientes ...</p>
            </div>
          ) : (
            <table className="table shadow  table-responsive   " ref={tableRef}>
              <thead className="table-secondary table opacity-10 rounded-top-5  shadow">
                <tr>
                  <th className="custom-border-left  ">Expediente</th>
                  <th>Pretensión </th>
                  <th>Docente</th>
                  <th>Juez</th>
                  <th>Especialista </th>
                  <th>Demandante</th>
                  <th>Demandado</th>
                  <th>Materia</th>
                  <th className="custom-border-right">Monto</th>
                </tr>
              </thead>
              <tbody>
                {expediente.map((data) => (
                  <tr key={data._id}>
                    <td>
                      {data._id && (
                        <Link to={`/vista_expediente/${data._id}`}>
                          {data.nexpediente}
                        </Link>
                      )}
                    </td>
                    <td>{data.datagrupo[0]?.datapretension?.nombre}</td>
                    <td>{data.datacurso[0]?.namedocente}</td>
                    <td>
                      {data.datagrupo[0]?.alumnose
                        .filter((alumno) => alumno.rol_id === "Juez")
                        .map((alumno) => alumno.nombre)
                        .join(" , ")}
                    </td>
                    <td>
                      {data.datagrupo[0]?.alumnose
                        .filter((alumno) => alumno.rol_id === "Especialista")
                        .map((alumno) => alumno.nombre)
                        .join(" , ")}
                    </td>
                    <td>
                      {data.datagrupo[0]?.alumnose
                        .filter((alumno) => alumno.rol_id === "Demandante")
                        .map((alumno) => alumno.nombre)
                        .join(" , ")}
                    </td>
                    <td>
                      {data.datagrupo[0]?.alumnose
                        .filter((alumno) => alumno.rol_id === "Demandado")
                        .map((alumno) => alumno.nombre)
                        .join(", ")}
                    </td>
                    {/* <td>{data.alumnoData[0].name}</td> */}
                    <td>{data.datagrupo[0]?.datapretension?.tipo}</td>
                    <td>{data.demanda ? data.demanda?.monto_soles : null}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
}

export default RepoExperient;
