import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const EditPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    window.history.back();
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      alert("Las contraseñas no coinciden.");
      return;
    }
    try {
      setLoading(true);

      const updatedPassword = {
        password: password,
      };

      const response = await axios.put(
        `${window.BACKEND_URL}users/${id}/password`,
        updatedPassword,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        window.history.back();
      } else {
        console.error(
          "Error al actualizar la contraseña:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error al procesar la solicitud:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="container bg-custom my-4 py-3">
        <h2 className=" mb-0">Editar contraseña</h2>
        <div className="col-8 ">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Nueva Contraseña:
              </label>
              <div className="input-group">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  id="password"
                  name="password"
                  value={password}
                  onChange={handlePasswordChange}
                />
                <button
                  type="button"
                  className="btn btn-stemis"
                  onClick={handleTogglePasswordVisibility}
                >
                  {showPassword ? "Ocultar" : "Mostrar"}
                </button>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="confirmPassword" className="form-label">
                Confirmar Contraseña:
              </label>
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                id="confirmPassword"
                name="confirmPassword"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
            </div>
            <div className="d-flex justify-content-between">
              <button
                type="button"
                className="btn btn-cancelar"
                onClick={handleCancel}
              >
                Regresar
              </button>
              <button type="submit" className="btn btn-stemis">
                {loading ? "Cargando..." : "Guardar"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditPassword;
