import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const EditNotiCu = () => {
  const { id, idnoti } = useParams();

  const [notifica, setNotifica] = useState({
    sumilla: "",
    observaciones: "",
    file: "",
  });

  const [file, setFile] = useState(null); // Estado para almacenar el archivo seleccionado por el usuario

  const [isLoading, setLoading] = useState(true);

  const getNotiCuaderno = async () => {
    setLoading(true); // Iniciar carga
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}docente/cuaderno_noti_revisar/${id}/notificacion/${idnoti}`
      );

      setNotifica(response.data);
    } catch (error) {
      console.error("Error al obtener la notificación:", error);
    } finally {
      setLoading(false); // Detener carga
    }
  };

  useEffect(() => {
    getNotiCuaderno();
  }, []);

  const handleNotificaChange = (field, value) => {
    setNotifica({ ...notifica, [field]: value });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]); // Obtener el archivo seleccionado por el usuario
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData(); // Crear un objeto FormData para enviar datos de formulario que incluya el archivo
      formData.append("archivo", file); // Agregar el archivo al FormData
      formData.append("sumilla", notifica.sumilla); // Agregar otros datos del formulario al FormData
      formData.append("observaciones", notifica.observaciones);

      await axios.put(
        `${window.BACKEND_URL}docente/cuaderno_notificacion/${id}/notificacion/${idnoti}`,
        formData, // Enviar el FormData en lugar del objeto notifica
        {
          headers: {
            "Content-Type": "multipart/form-data", // Establecer encabezado para el FormData
          },
        }
      );
      Swal.fire(`Actualizado Correctamente `, "", "success");
      window.history.back();
    } catch (error) {
      console.error("Error al actualizar el expediente", error);
    }
  };

  const handleCancel = () => {
    window.history.back();
  };

  return (
    <>
      <section>
        <div className="container-fluid">
          <h2>Editar Notificacion</h2>
          <form
            className="row g-3 needs-validation mt-4"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
            noValidate
          >
            <div className="col-md-8 px-4">
              <div className="row">
                <div className="col-6 col-md-4 mt-2">
                  <p className="text-left mt-2">{notifica.acto}</p>
                </div>
                <div className="col-md-8 p-0 mt-2">
                  <input
                    type="text"
                    value={notifica.sumilla}
                    onChange={(e) =>
                      handleNotificaChange("sumilla", e.target.value)
                    }
                    className="form-control"
                    name="sumilla"
                    required
                  />
                  <div className="invalid-feedback">Campo Obligatorio.</div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-6 col-md-4">
                  <p>Observaciones:</p>
                </div>
                <div className="col-md-8 p-0 mt-2">
                  <textarea
                    value={notifica.observaciones}
                    onChange={(e) =>
                      handleNotificaChange("observaciones", e.target.value)
                    }
                    className="form-control"
                    name="observaciones"
                    style={{
                      border: "1px solid rgb(231, 231, 231)",
                      height: "100px",
                      fontSize: "14px",
                      color: "#4b4b4b",
                    }}
                  ></textarea>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-6 col-md-4">
                  <p>Archivo:</p>
                </div>
                <div className="col-md-8 p-0 mt-2">
                  <input
                    type="file"
                    onChange={handleFileChange} // Manejar cambios en el campo de archivo
                    className="form-control"
                    name="archivo"
                    accept="application/pdf" // Solo permitir archivos PDF
                  />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12 px-2">
                <a
                  className="p-3 rounded btn"
                  target="_blank"
                  href={`${window.BACKEND_URL}uploads/${notifica.file}`}
                  download={`${window.BACKEND_URL}uploads/${notifica.file}`}
                >
                  Archivo subido anteriormente
                  <i className="fa-solid fa-file-import mx-1"></i>
                </a>
              </div>
            </div>

            <div className="row">
              <div className="col-6 p-0 mb-3 mt-4">
                <Link
                  onClick={handleCancel}
                  type="button"
                  className="btn btn-cancelar px-2"
                >
                  <i className="fa-solid fa-times me-2"></i>
                  Cancelar
                </Link>
              </div>
              <div className="col-6 mb-5 mt-4 d-flex flex-row-reverse">
                <button type="submit" className="btn btn-stemis px-5">
                  Grabar
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default EditNotiCu;
