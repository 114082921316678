import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules

const Home = () => {
  const [cursos, setCursos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalAlumnos, setTotalAlumnos] = useState(0);
  const _id = sessionStorage.getItem("_id");
  const [gruposPorCurso, setGruposPorCurso] = useState({});
  const [expedientesPorCurso, setExpedientesPorCurso] = useState([]);
  const [grupos, setGrupo] = useState([]);
  const style3 = {
    backgroundColor: "#009688",
    color: "#ffffff",
    fontSize: "13.5px",
    position: "relative",
    top: "-22px",
    textDecoration: "none",
  };
  const style1 = {
    maxHeight: "200px",
    maxWidth: "260px",
    height: "100%",
    objectFit: "cover",
    minHeight: "200px",
    minWidth: "260px",
    width: "100%",
  };

  const style2 = {
    height: "250px",
    width: "260px",
    backgroundColor: "#d9ebe9",
  };

  const getCursos = () => {
    axios
      .get(`${window.BACKEND_URL}cursosimu`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        // Filtrar los cursos por el docente actual
        const cursosFiltrados = response.data.filter(
          (curso) => curso.docente_id === _id
        );

        axios
          .get(`${window.BACKEND_URL}cursogrupo`, {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            // Contar los grupos por curso
            const gruposPorCurso = cursosFiltrados.map((curso) => {
              const numGrupos = response.data.filter(
                (grupo) => grupo.curso_id === curso.id
              ).length;
              return { curso_id: curso.id, numGrupos };
            });

            axios
              .get(`${window.BACKEND_URL}expediente`, {
                headers: {
                  Authorization: `${sessionStorage.getItem("token")}`,
                },
              })
              .then((response) => {
                // Contar los expedientes por curso
                const expedientesPorCurso = cursosFiltrados.map((curso) => {
                  const numExpedientes = response.data.filter(
                    (expediente) => expediente.curso_id === curso.id
                  ).length;
                  return { curso_id: curso.id, numExpedientes };
                });
                setExpedientesPorCurso(expedientesPorCurso);
              });
            let total = 0;
            cursosFiltrados.forEach((curso) => {
              total += curso.alumnos_id.length;
            });

            setCursos(cursosFiltrados);

            setTotalAlumnos(total);
            setGrupo(response.data);
            setGruposPorCurso(gruposPorCurso);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching grupos:", error);
            setLoading(false);
          });
      })
      .catch((error) => {
        console.error("Error fetching cursos:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getCursos();
    fetchData();
  }, []);

  const [ancho, setAncho] = useState("100%");
  const [PerView, setPerView] = useState(0);
  const [Between, setBetween] = useState(0);
  useEffect(() => {
    const actualizarAncho = () => {
      const anchoPantalla = window.innerWidth;
      console.log(anchoPantalla);
      if (anchoPantalla <= 914) {
        setAncho("55rem");
        setPerView(2);
        setBetween(300);
      }
      if (anchoPantalla <= 1208) {
        setAncho("55rem");
        setPerView(2);
        setBetween(300);
      } else if (anchoPantalla <= 1242) {
        setAncho("56rem");
        setPerView(2);
        setBetween(330);
      } else if (anchoPantalla <= 1366) {
        setAncho("58rem");
        setPerView(4);
        setBetween(-60);
      } else if (anchoPantalla <= 1455) {
        setAncho("63rem");
        setPerView(2);
        setBetween(450);
      } else if (anchoPantalla <= 1517) {
        setAncho("65rem");
        setPerView(3);
        setBetween(100);
      } else if (anchoPantalla <= 1707) {
        setAncho("70rem");
        setPerView(3);
        setBetween(140);
      } else if (anchoPantalla <= 1745) {
        setAncho("78rem");
        setPerView(4);
        setBetween(20);
      } else if (anchoPantalla <= 1777) {
        setAncho("78rem");
        setPerView(4);
        setBetween(40);
      } else if (anchoPantalla <= 1798) {
        setAncho("79rem");
        setPerView(5);
        setBetween(-35);
      } else if (anchoPantalla <= 1821) {
        setAncho("90rem");
        setPerView(3);
        setBetween(290);
      } else if (anchoPantalla <= 2000) {
        setAncho("90rem");
        setPerView(4);
        setBetween(90);
      } else if (anchoPantalla <= 2049) {
        setAncho("92rem");
        setPerView(4);
        setBetween(110);
      } else if (anchoPantalla <= 2560) {
        setAncho("92rem");
        setPerView(4);
        setBetween(110);
      } else if (anchoPantalla <= 2732) {
        setAncho("91rem");
        setPerView(4);
        setBetween(80);
      }
    };

    window.addEventListener("resize", actualizarAncho);
    actualizarAncho();

    return () => window.removeEventListener("resize", actualizarAncho);
  }, [setAncho, setPerView, setBetween]);
  const [gruposCurso, setGruposCurso] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}docente/grupos_por_docente_id/${_id}`
      );
      setGruposCurso(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error al obtener los grupos de curso:", error);
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className="text-center mt-5">
          <i className="fa-solid fa-spinner fa-spin icon fs-1 bg-stemis"></i>
          <p>Cargando Cursos...</p>
        </div>
      ) : (
        <>
          <section>
            <h1 className="pt-3 pb-2 ps-2">
              {" "}
              <b>Mis cursos </b>{" "}
            </h1>
            <div className="d-inline d-sm-none">
              <div className="row">
                {cursos.map((curso) => (
                  <div className="col-6 mb-3" key={curso.id}>
                    <Link to={`/curso-docente/${curso._id}`} className="card">
                      <div className="card-body">
                        <h5 className="card-title">
                          {curso.informacion_titulo[0].nombre}
                        </h5>
                        <p className="p-0 m-0">
                          <b>Año: </b> {curso.anio}
                        </p>
                        <p className="p-0 m-0">
                          <b>Sección: </b> {curso.seccion}
                        </p>
                        <p className="p-0 m-0">
                          <b>Turno: </b>
                          {curso.informacion_turno[0].turno}
                        </p>
                      </div>
                    </Link>
                  </div>
                ))}

                {/* Agrega más tarjetas según sea necesario */}
              </div>
            </div>

            <div
              className="d-none d-sm-inline  p-0 m-0 "
              // style={{ width: ancho }}
            >
              <Swiper
                slidesPerView={PerView}
                spaceBetween={Between}
                pagination={{ clickable: true }}
                modules={[Pagination]}
                className="pb-5 ps-3 px-0 mx-0"
                breakpoints={{}}
                style={{ width: ancho }}
              >
                {cursos.map((curso) => (
                  <SwiperSlide className="p-0 m-0" key={curso.id}>
                    <div key={curso.id} className=" p-0 m-0 ">
                      <div className="shadow rounded-2  " style={style2}>
                        <div className="row-4">
                          <Link to={`/curso-docente/${curso._id}`}>
                            <img
                              src={`${window.BACKEND_URL}uploads/${curso.imagen}`}
                              style={style1}
                              alt="..."
                              className="img-fluid rounded-top"
                            />
                          </Link>
                        </div>
                        <div className="row-4">
                          <h3 className=" rounded-1 ms-1 px-1" style={style3}>
                            {curso.informacion_titulo[0].nombre}
                          </h3>
                        </div>
                        <div
                          className=" row-4 d-flex align-items-start mx-1 text-center "
                          style={{
                            marginTop: "-16px",
                            position: "relative",
                          }}
                        >
                          <div className="col-3 p-0 m-0">
                            <p className="p-0 m-0">
                              <b>Año: </b> {curso.anio}
                            </p>
                          </div>
                          <div className="col-4 p-0 m-0 ">
                            <p className="p-0 m-0">
                              <b>Sección: </b> {curso.seccion}
                            </p>
                          </div>
                          <div className="col-5 p-0 m-0 ">
                            <p className="p-0 m-0">
                              <b>Turno: </b>
                              {curso.informacion_turno[0].turno}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </section>

          <section>
            <h2 className="px-3 my-3" style={{ color: "#454C5B" }}>
              Resumen de estadística
            </h2>
            <div className="container-fluid">
              <div className="row d-flex justify-content-center">
                <div className="col mx-3 rounded bg-body-secondary shadow">
                  <h5>Secciones</h5>
                  <div className="row">
                    <p className="col fs-4"> {cursos.length || "0"} </p>
                    <div className="col fs-2" style={{ color: "#009688" }}>
                      <i className="fa-solid fa-users-between-lines fa-2x"></i>{" "}
                    </div>
                  </div>
                </div>
                <div className="col mx-3 rounded bg-body-secondary shadow">
                  <h5>Grupos</h5>
                  <div className="row">
                    <p className="col fs-4"> {gruposCurso.length || "0"}</p>
                    <div className="col fs-2" style={{ color: "#009688" }}>
                      <i className="fa-solid fa-users fa-2x"></i>
                    </div>
                  </div>
                </div>
                <div className="col mx-3 rounded bg-body-secondary shadow">
                  <h5>Alumnos</h5>
                  <div className="row">
                    <p className="col fs-4">{totalAlumnos}</p>
                    <div className="col fs-2" style={{ color: "#009688" }}>
                      <i className="fas fa-user-graduate fa-2x"></i>
                    </div>
                  </div>
                </div>
                <div className="col mx-3 rounded bg-body-secondary shadow">
                  <h5>Expedientes</h5>
                  <div className="row">
                    <p className="col fs-4">{gruposCurso.length || "0"}</p>
                    <div className="col fs-2" style={{ color: "#009688" }}>
                      <i className="fas fa-folder-open fa-2x"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Home;
