import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
function ResetPassword() {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false); // Estado para rastrear si el formulario ha sido enviado

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setMessage("Las contraseñas no coinciden");
      return;
    }
    const token = window.location.pathname.split("/").pop(); // Obtener el token de la URL
    try {
      setFormSubmitted(true); // Marcar el formulario como enviado
      const response = await axios.put(
        `${window.BACKEND_URL}users/reset-password/${token}`,
        { newPassword }
      );
      setMessage(response.data.mensaje);
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error) {
      setMessage("Error al restablecer la contraseña");
      console.error("Error:", error);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="container-sm d-flex justify-content-center align-items-center vh-100">
      <div className="container-sm bg-light rounded-5 shadow ">
        <img
          className="mx-auto d-block "
          src="/img/logo stemis normal.png"
          alt=""
          style={{
            width: "17rem",
          }}
        />

        <form className="px-5 pb-5" onSubmit={handleSubmit}>
          <div className="text-center mb-4">
            <h2 className="fw-bold">Establecer una Contraseña Nueva</h2>
            <h3 className="text-secondary">Escribe tu nueva contraseña </h3>
          </div>
          <div className="mb-3">
            <label htmlFor="newPassword" className="form-label">
              Nueva contraseña
            </label>
            <div className="input-group">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? "Ocultar" : "Mostrar"}
              </button>
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="confirmPassword" className="form-label">
              Confirmar contraseña
            </label>
            <input
              type="password"
              className="form-control"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <div className="d-flex justify-content-between">
            <button
              type="submit"
              className="btn btn-stemis"
              disabled={formSubmitted}
            >
              Restablecer contraseña
            </button>
          </div>
        </form>
        {message && <div className="mt-3 alert alert-info">{message} </div>}
      </div>
    </div>
  );
}

export default ResetPassword;
