import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

import Select from "react-select";

const EditGrupos = () => {
  const { idGrupo } = useParams();
  const [grupo, setGrupo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedAlumnos, setSelectedAlumnos] = useState({
    Demandante: [],
    Demandado: [],
    Juez: [],
    Especialista: [],
  });
  const [alumnosCurso, setAlumnosCurso] = useState([]);
  const [alumnosNombres, setAlumnosNombres] = useState([]);

  useEffect(() => {
    const fetchGrupo = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}docente/cursogrupo/edit/grupo/${idGrupo}`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );
        setGrupo(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching grupo:", error);
        setLoading(false);
      }
    };

    fetchGrupo();
  }, [idGrupo]);

  useEffect(() => {
    if (grupo) {
      const fetchAlumnosCurso = async () => {
        try {
          const response = await axios.get(
            `${window.BACKEND_URL}docente/cursos/get/${grupo.curso_id}`,
            {
              headers: {
                Authorization: `${sessionStorage.getItem("token")}`,
              },
            }
          );
          setAlumnosCurso(response.data.alumnos_id);
        } catch (error) {
          console.error("Error fetching alumnos curso:", error);
        }
      };

      const fetchAlumnosNombres = async () => {
        try {
          const response = await axios.get(
            `${window.BACKEND_URL}docente/users`,
            {
              headers: {
                Authorization: `${sessionStorage.getItem("token")}`,
              },
            }
          );
          setAlumnosNombres(response.data);
        } catch (error) {
          console.error("Error fetching alumnos nombres:", error);
        }
      };

      fetchAlumnosCurso();
      fetchAlumnosNombres();
    }
  }, [grupo]);

  const handleSubmit = async () => {
    try {
      const updatedGrupo = {
        ...grupo,
        alumnose: [
          ...selectedAlumnos.Demandante.map((alumnoId) => ({
            alumno_id: alumnoId,
            rol_id: "Demandante",
          })),
          ...selectedAlumnos.Demandado.map((alumnoId) => ({
            alumno_id: alumnoId,
            rol_id: "Demandado",
          })),
          ...selectedAlumnos.Juez.map((alumnoId) => ({
            alumno_id: alumnoId,
            rol_id: "Juez",
          })),
          ...selectedAlumnos.Especialista.map((alumnoId) => ({
            alumno_id: alumnoId,
            rol_id: "Especialista",
          })),
        ],
      };

      const response = await axios.put(
        `${window.BACKEND_URL}docente/grupo/put/${idGrupo}`,
        updatedGrupo,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      console.log("Grupo actualizado:", response.data);
      window.history.back();
    } catch (error) {
      console.error("Error updating grupo:", error);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!grupo) {
    return <p>No se encontró el grupo.</p>;
  }

  return (
    <>
      <div className="container">
        <h2 className="py-2 pt-3">Editar Grupo</h2>

        <div className="row">
          <div className="col-md-6">
            <h4>Selecciona los alumnos para cada rol:</h4>
            {["Demandante", "Demandado", "Juez", "Especialista"].map((rol) => (
              <div key={rol}>
                <p>{rol}:</p>
                <Select
                  options={alumnosCurso.map((alumno) => ({
                    value: alumno.alu_id,
                    label:
                      alumnosNombres.find((n) => n._id === alumno.alu_id)
                        ?.name || alumno.alu_id,
                  }))}
                  value={selectedAlumnos[rol].map((id) => ({
                    value: id,
                    label: alumnosNombres.find((n) => n._id === id)?.name || id,
                  }))}
                  onChange={(selectedOptions) => {
                    const selectedIds = selectedOptions.map(
                      (option) => option.value
                    );
                    setSelectedAlumnos((prevSelectedAlumnos) => ({
                      ...prevSelectedAlumnos,
                      [rol]: selectedIds,
                    }));
                  }}
                  isMulti
                />
              </div>
            ))}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-6">
            <button className="btn btn-stemis" onClick={handleSubmit}>
              Guardar
            </button>
            <button
              type="button"
              className="btn btn-cancelar ms-2"
              onClick={() => {
                window.history.back();
              }}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditGrupos;
