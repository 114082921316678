import React from "react";
import { Link } from "react-router-dom";

const Sliderdoc = () => {
  return (
    <>
      <ul className="nav nav-pills flex-column mb-auto px-0 centered-list">
        <li className="nav-item">
          <a
            href="/"
            className="nav-link link-body-emphasis"
            aria-current="page"
          >
            <i className="fa-solid fa-graduation-cap icon px-1"></i>
            Mis cursos
          </a>
        </li>

        <li className="nav-item">
          <Link
            to={`/repositorio-expediente`}
            className="nav-link link-body-emphasis"
          >
            <i className="fa-regular fa-folder-open icon px-1  "></i>
            Repositorio de Expedientes
          </Link>
        </li>

        <li>
          <Link to={`/calendario`} className="nav-link link-body-emphasis">
            <i className="fa-solid fa-calendar-days icon px-1"></i>
            Calendario de Audiencias
          </Link>
        </li>
        <li>
          <Link
            to={`/normatividad/uni`}
            className="nav-link link-body-emphasis"
          >
            <i className="fa-solid fa-book icon px-1"></i>
            Normativa
          </Link>
        </li>
        <li>
          <Link to={`/estadistica`} className="nav-link link-body-emphasis">
            <i className="fa-solid fa-chart-simple icon px-1"></i>
            Estadisticas
          </Link>
        </li>

        {/*    <li>
          <a href="#" className="nav-link link-body-emphasis">
            <i className="fa-solid fa-scale-balanced icon px-1"></i>
            Normatividad
          </a>
        </li> */}
      </ul>
    </>
  );
};

export default Sliderdoc;
