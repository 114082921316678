import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

const LibroCalifica = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [cargando, setCargando] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}docente/vercalificaciones/${id}`
        );
        setData(response.data);
        console.log(response.data);
        setCargando(false);
      } catch (error) {
        console.error("Error al obtener los datos:", error);
        setCargando(false);
      }
    };

    fetchData();
  }, [id]);

  return (
    <>
      <section>
        {cargando ? (
          <div className="text-center mt-5">
            <i className="fa-solid fa-spinner fa-spin icon fs-1 bg-stemis"></i>
            <p>Cargando Calificaciones...</p>
          </div>
        ) : (
          data.map((expediente, index) => (
            <div key={index} className="px-3">
              <div className="container-fluid mt-3 border shadow rounded-4 mb-3">
                <div className="row mb-3 text-left pt-2">
                  <div className="col-6 col-md-6">
                    <Link to={`/expediente-docente/${expediente.id}/${id}`}>
                      <h5>Expediente: {expediente.n_expe}</h5>
                    </Link>
                  </div>
                  <div className="col-3 col-md-3">
                    <p>Materia: {expediente.pretencion?.tipo}</p>
                  </div>
                  <div className="col-3 col-md-3">
                    <p>Pretensión: {expediente.pretencion?.nombre}</p>
                  </div>
                </div>
                <div className="table-responsive ">
                  <table className="table table-sm">
                    <thead>
                      <tr className="table-active">
                        <th scope="col">Juez y Especialista</th>
                        {expediente.alumnose.find(
                          (alumno) => alumno.rol === "Juez"
                        ) &&
                          Object.keys(
                            expediente.alumnose.find(
                              (alumno) => alumno.rol === "Juez"
                            )
                          )
                            .filter(
                              (key) =>
                                key.includes("Escritos") ||
                                key.includes("Notificacion")
                            )
                            .map((notaKey, i) => (
                              <th key={i} scope="col">
                                {notaKey.includes("Escritos")
                                  ? "Escrito"
                                  : "Notificación"}{" "}
                                {notaKey.replace(/\D/g, "")}
                              </th>
                            ))}
                        <th scope="col">Promedio</th>
                      </tr>
                    </thead>
                    <tbody>
                      {expediente.alumnose
                        .filter((alumno) =>
                          ["juez", "especialista"].includes(
                            alumno.rol.toLowerCase()
                          )
                        )
                        .map((alumno, idx) => {
                          const notas = Object.keys(alumno)
                            .filter(
                              (key) =>
                                key.includes("Escritos") ||
                                key.includes("Notificacion")
                            )
                            .map((notaKey) => alumno[notaKey]);
                          const promedio =
                            notas.length > 0
                              ? notas.reduce((acc, curr) => acc + curr, 0) /
                                notas.length
                              : 0;
                          return (
                            <tr key={idx}>
                              <td>{alumno.name}</td>
                              {Object.keys(alumno)
                                .filter(
                                  (key) =>
                                    key.includes("Escritos") ||
                                    key.includes("Notificacion")
                                )
                                .map((notaKey, i) => (
                                  <td key={i}>
                                    {alumno[notaKey] ? alumno[notaKey] : "-"}
                                  </td>
                                ))}
                              <td>{promedio.toFixed(2)}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="table-responsive ">
                  <table className="table table-sm">
                    <thead>
                      <tr className="table-active">
                        <th scope="col">Parte Demandante</th>
                        <th scope="col">Demanda</th>
                        {expediente.alumnose[0] &&
                          Object.keys(expediente.alumnose[0])
                            .filter(
                              (key) =>
                                key.startsWith("Escritos") ||
                                key.startsWith("Notificacion")
                            )
                            .map((notaKey, i) => (
                              <th key={i} scope="col">
                                {notaKey.includes("Escritos")
                                  ? "Escrito"
                                  : "Notificación"}{" "}
                                {notaKey.replace(/\D/g, "")}
                              </th>
                            ))}
                        <th scope="col">Promedio</th>
                      </tr>
                    </thead>
                    <tbody>
                      {expediente.alumnose
                        .filter((alumno) => alumno.rol === "Demandante")
                        .map((alumno, idx) => {
                          const notas = Object.keys(alumno)
                            .filter(
                              (key) =>
                                key.startsWith("Escritos") ||
                                key.startsWith("Notificacion")
                            )
                            .map((notaKey) => alumno[notaKey]);
                          const promedio =
                            notas.length > 0
                              ? notas.reduce((acc, curr) => acc + curr, 0) /
                                notas.length
                              : 0;
                          return (
                            <tr key={idx}>
                              <td>{alumno.name}</td>
                              <td>
                                {alumno.rol === "Demandante"
                                  ? alumno.dema
                                  : "-"}
                              </td>
                              {Object.keys(alumno)
                                .filter(
                                  (key) =>
                                    key.startsWith("Escritos") ||
                                    key.startsWith("Notificacion")
                                )
                                .map((notaKey, i) => (
                                  <td key={i}>
                                    {alumno[notaKey] ? alumno[notaKey] : "-"}
                                  </td>
                                ))}
                              <td>
                                {alumno.rol === "Demandante"
                                  ? promedio.toFixed(2)
                                  : "-"}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="table-responsive ">
                  <table className="table table-sm">
                    <thead className="bg-secondary">
                      <tr className="table-active">
                        <th scope="col">Parte Demandado</th>
                        {expediente.alumnose[0] &&
                          Object.keys(expediente.alumnose[0])
                            .filter(
                              (key) =>
                                key.startsWith("Escritos") ||
                                key.startsWith("Notificacion")
                            )
                            .map((notaKey, i) => (
                              <th key={i} scope="col">
                                {notaKey.includes("Escritos")
                                  ? "Escrito"
                                  : "Notificación"}{" "}
                                {notaKey.replace(/\D/g, "")}
                              </th>
                            ))}
                        <th scope="col">Promedio</th>
                      </tr>
                    </thead>
                    <tbody>
                      {expediente.alumnose
                        .filter((alumno) => alumno.rol === "Demandado")
                        .map((alumno, idx) => {
                          const notas = Object.keys(alumno)
                            .filter(
                              (key) =>
                                key.startsWith("Escritos") ||
                                key.startsWith("Notificacion")
                            )
                            .map((notaKey) => alumno[notaKey]);
                          const promedio =
                            notas.length > 0
                              ? notas.reduce((acc, curr) => acc + curr, 0) /
                                notas.length
                              : 0;
                          return (
                            <tr key={idx}>
                              <td>{alumno.name}</td>
                              {Object.keys(alumno)
                                .filter(
                                  (key) =>
                                    key.startsWith("Escritos") ||
                                    key.startsWith("Notificacion")
                                )
                                .map((notaKey, i) => (
                                  <td key={i}>
                                    {alumno[notaKey] ? alumno[notaKey] : "-"}
                                  </td>
                                ))}
                              <td>{promedio.toFixed(2)}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ))
        )}
      </section>
    </>
  );
};

export default LibroCalifica;
