import React, { useState } from "react";

import TituloCurso from "../components/titulodoc";
import ListAlumnos from "./Components/lista_alumnosdoc";
import Anuncios from "./Components/anuncio";
import ListGruop from "./Grupo/listgroup_doc";
import LibroCalifica from "./Components/libro_cali";
import Salaaudi from "./Components/salaaudi";
import Rubricas from "./Components/Rubrica/rubricas";
import Detallescurs from "./Components/detalles_curso";

const InfocursoDoc = () => {
  // const ListAlumnosMemo = React.memo(ListAlumnos);
  // const ListGruopMemo = React.memo(ListGruop);
  // const LibroCalificaMemo = React.memo(LibroCalifica);
  // const SalaaudiMemo = React.memo(Salaaudi);
  // const RubricasMemo = React.memo(Rubricas);
  // const AnunciosMemo = React.memo(Anuncios);
  // const DetallescursMemo = React.memo(Detallescurs);

  const [seccion, setSeccion] = useState(
    localStorage.getItem("seccion") || "cursos"
  );

  const cambiarSeccion = (nuevaSeccion) => {
    setSeccion(nuevaSeccion);
    localStorage.setItem("seccion", nuevaSeccion);
  };
  return (
    <>
      <div className="container-fluid rounded-end-4">
        {/* Usar la variable cursoNombre en lugar de la verificación directa */}

        {/* <h2 className="py-2 pt-3">{datos[0]?.informacion_titulo[0]?.nombre}</h2> */}
        <TituloCurso />
        <hr className="p-0 m-0" />
        <section>
          <div className="navbar w-100 d-flex justify-content-between p-2  ">
            {/* Botones para cambiar la sección */}
            <a
              onClick={() => cambiarSeccion("cursos")}
              style={{
                borderBottom:
                  seccion === "cursos" ? "2px solid #009688" : "none ",
                fontWeight: "bold",
                color: "#009688",
                MozAnimation: "none",
                marginRight: "5px",
                cursor: "pointer",
              }}
            >
              <i class="fa-solid fa-user-graduate "></i>
              <span class="d-none d-sm-inline ps-2"> Lista de Alumnos</span>
            </a>
            <a
              onClick={() => cambiarSeccion("grupos")}
              style={{
                borderBottom:
                  seccion === "grupos" ? "2px solid #009688" : "none",
                fontWeight: "bold",
                color: "#009688",
                MozAnimation: "none",
                marginRight: "5px",
                cursor: "pointer",
              }}
            >
              <i class="fa-solid fa-users "></i>
              <span class="d-none d-sm-inline ps-2">Grupos</span>
            </a>
            <a
              onClick={() => cambiarSeccion("libro_califica")}
              style={{
                borderBottom:
                  seccion === "libro_califica" ? "2px solid #009688" : "none",
                fontWeight: "bold",
                color: "#009688",
                MozAnimation: "none",
                marginRight: "5px",
                cursor: "pointer",
              }}
            >
              <i class="fa-solid fa-scale-unbalanced-flip "></i>
              <span class="d-none d-sm-inline ps-2">
                {" "}
                Libro de Calificaciones
              </span>
            </a>{" "}
            {/* <a
              onClick={() => cambiarSeccion("sala_audiencias")}
              style={{
                borderBottom:
                  seccion === "sala_audiencias" ? "2px solid #009688" : "none",
                fontWeight: "bold",
                color: "#009688",
                MozAnimation: "none",
                marginRight: "5px",
                cursor: "pointer",
              }}
            >
              --
            </a>{" "} */}
            <a
              onClick={() => cambiarSeccion("rubricas")}
              style={{
                borderBottom:
                  seccion === "rubricas" ? "2px solid #009688" : "none",
                fontWeight: "bold",
                color: "#009688",
                MozAnimation: "none",
                marginRight: "5px",
                cursor: "pointer",
              }}
            >
              <i class="fa-solid fa-ranking-star "></i>
              <span class="d-none d-sm-inline ps-2">Rúbricas</span>
            </a>{" "}
            <a
              onClick={() => cambiarSeccion("anuncios")}
              style={{
                borderBottom:
                  seccion === "anuncios" ? "2px solid #009688" : "none",
                fontWeight: "bold",
                color: "#009688",
                MozAnimation: "none",
                marginRight: "5px",
                cursor: "pointer",
              }}
            >
              <i class="fa-solid fa-bell "></i>
              <span class="d-none d-sm-inline ps-2">Anuncios</span>
            </a>
            <a
              onClick={() => cambiarSeccion("detallecurso")}
              style={{
                borderBottom:
                  seccion === "detallecurso" ? "2px solid #009688" : "none",
                fontWeight: "bold",
                color: "#009688",
                MozAnimation: "none",
                marginRight: "5px",
                cursor: "pointer",
              }}
            >
              <i class="fa-solid fa-file-lines "></i>
              <span class="d-none d-sm-inline ps-2">Detalles de Curso</span>
            </a>
          </div>
        </section>

        {/* Renderizado condicional de la sección de cursos */}
        {seccion === "cursos" && <ListAlumnos />}
        {seccion === "grupos" && <ListGruop />}
        {seccion === "libro_califica" && <LibroCalifica />}
        {/* {seccion === "sala_audiencias" && <Salaaudi />} */}
        {seccion === "rubricas" && <Rubricas />}
        {seccion === "anuncios" && <Anuncios />}
        {seccion === "detallecurso" && <Detallescurs />}
      </div>
    </>
  );
};

export default InfocursoDoc;
