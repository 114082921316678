import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

import Swal from "sweetalert2";
import "react-datepicker/dist/react-datepicker.css";

function CuaderAddEscrito() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { cuader_id } = useParams();
  const [username] = useState(sessionStorage.getItem("userName"));
  const [_id] = useState(sessionStorage.getItem("_id"));
  const [photo] = useState(sessionStorage.getItem("photo"));
  const [expediente, setExpediente] = useState([]);

  const [ExpedienteCuaderno, setExpedienteCuaderno] = useState([]);
  const [titulocuso, setTituloCurdo] = useState([]);

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        console.log(id);
        console.log(cuader_id);
        const response123 = await axios.get(
          `${window.BACKEND_URL}alumno/expe_cuaderno/notifi/getid/${cuader_id}`,
          {
            params: { _id: id },
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );
        setExpedienteCuaderno(response123.data);
        console.log(response123.data);

        const response = await axios.get(
          `${window.BACKEND_URL}alumno/expediente/num_expediente/get/${id}`,
          {
            params: { _id: id },
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );
        const response2 = await axios.get(
          `${window.BACKEND_URL}alumno/titulo/get/${response.data[0].datacurso[0].titulo_id}`,
          {
            params: { _id: id },
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );

        // Actualizar el estado de los grupos con los nombres de los usuarios
        setTituloCurdo(response2.data.nombre);
        console.log(response.data);
        setExpediente(response.data);
      } catch (error) {
        console.error("Error fetching :", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);
  const handleCancel = () => {
    window.history.back();
  };
  const confirmsFormu = async (e) => {
    e.preventDefault();
    if (submitting) {
      return;
    }

    setSubmitting(true); // Marcar el formulario como enviado

    Swal.fire({
      title: "Cargando",
      text: "Subiendo archivos...",
      timer: 2000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    // Obtener el formulario utilizando su ID
    const form = document.getElementById("myForm");
    // Verificar si el formulario se encontró

    if (!form) {
      console.error("No se encontró el formulario con el ID proporcionado");
      return;
    }

    const formData = new FormData();

    formData.append("file", e.target.file.files[0]);
    formData.append("observaciones", e.target.observaciones.value);
    formData.append("acto", "Escritos");
    formData.append("tipo", "Escritos");
    formData.append("estado", 0);
    formData.append("sumilla", e.target.sumilla.value);
    formData.append("user", username);
    formData.append("alum_id", _id);

    const Anuncio = {};

    Anuncio.alumno_id = _id;
    Anuncio.numexpe = expediente[0]?.nexpediente;
    Anuncio.expediente_id = expediente[0]?.curso_id;

    Anuncio.detalles = ` Agrego un escrito  `;

    try {
      await axios.put(
        `${window.BACKEND_URL}alumno/grupocurso/notifica/put/${expediente[0].grupo_id[0]._id}`,
        Anuncio,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );
      await axios.put(
        `${window.BACKEND_URL}alumno/expe_cuaderno/notifi/put/${cuader_id}`,
        formData,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      Swal.fire({
        icon: "success",
        title: "Guardado",
        text: "¡Se guardó correctamente!",
      });
      navigate(`/curso-alumno/${expediente[0].curso_id}`);
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false); // Marcar el formulario como no enviado
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType !== "application/pdf") {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "¡Por favor, selecciona un archivo PDF!",
        });
        event.target.value = ""; // Limpia la selección del archivo
      }
    }
  };

  return (
    <>
      <div className="container-fluid rounded-end-4 position-relative m-0 p-0">
        <div className="container-fluid mt-3 py-3">
          <strong class=" px-3 pt-4">Agregar Escritos</strong>
        </div>
        {loading ? (
          <p>Cargando...</p>
        ) : (
          <section>
            <div className="container-fluid mt-3">
              <form
                className="row g-3 needs-validation"
                onSubmit={confirmsFormu}
                id="myForm"
              >
                <div className="row">
                  <div className="col-md-8 px-4">
                    <div className="row">
                      <div className="col-md-4 mt-2">
                        <label htmlFor="inputEmail3">
                          <p>
                            N° de Expediente
                            <text className="text-danger">*</text>
                          </p>
                        </label>
                      </div>
                      <div className="col-md-8 p-0 mt-2">
                        <input
                          className="form-control"
                          value={ExpedienteCuaderno.nexpediente}
                          id="validationCustom01"
                          disabled
                        />
                        <div className="invalid-feedback">
                          Campo Obligatorio.
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 mt-2">
                        <label htmlFor="inputEmail3">
                          <p>
                            Sumilla<text className="text-danger">*</text>
                          </p>
                        </label>
                      </div>
                      <div className="col-md-8 p-0 mt-2">
                        <input
                          type="text"
                          className="form-control"
                          id="validationCustom01"
                          name="sumilla"
                          required
                          placeholder="Ingrese un sumilla..."
                        />
                        <div className="invalid-feedback">
                          Campo Obligatorio.
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-4">
                        <label htmlFor="inputEmail3">
                          <p>Observaciones</p>
                        </label>
                      </div>
                      <div className="col-md-8 p-0 mt-2">
                        <textarea
                          className="form-control"
                          name="observaciones"
                          id="observaciones"
                          style={{
                            border: "1px solid rgb(172, 172, 172)",
                            height: "100px",
                            fontSize: "14px",
                            color: "#4b4b4b",
                          }}
                        ></textarea>
                      </div>
                    </div>

                    <div className="row mt-3 mb-3">
                      <div className="col-md-12 px-2">
                        <div className="input-group">
                          <input
                            accept="application/pdf"
                            type="file"
                            className="form-control "
                            id="file"
                            name="file"
                            onChange={handleFileChange}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6 p-0 mb-3 mt-4">
                        <button
                          type="button"
                          className="btn px-5"
                          style={{
                            backgroundColor: "#ffffff",
                            color: "#868686",
                            borderColor: "#868686",
                            textAlign: "right",
                            fontWeight: "500",
                          }}
                          onClick={handleCancel}
                        >
                          Cancelar
                        </button>
                      </div>
                      <div className="col-6 mb-4 mt-4 d-flex flex-row-reverse ">
                        <button
                          id="myButton"
                          className=" btn-primary btn px-5"
                          type="submit"
                          disabled={submitting}
                        >
                          {submitting ? "Enviando..." : "Grabar"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>
        )}
      </div>
    </>
  );
}

export default CuaderAddEscrito;
